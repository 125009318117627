import {
    Text, Link,
    Center, Box, Table, Tbody, Tr, Td, OrderedList, ListItem, UnorderedList
} from "@chakra-ui/react";

//css
import styles from "../css/TermsAndConditions.module.css";

// components
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

function TermsAndConditions() {

    const handleEmailClick = (email: string) => {
        const mailtoLink = `mailto:${email}`;
        window.location.href = mailtoLink;
    };

    return (
        <>
            <Navbar />

            <Center>
                <Text className={styles.title}>利用規約</Text>
            </Center>

            <Center>
                <Box>
                    <Text className={styles.content}>株式会社Jelper Club (以下「当社」といいます。) が、「Jelper Club」の名称で提供する国内企業と海外の学生・学生団体・キャリアセンター等の橋渡しを行うサービス (以下「本サービス」といいます。) について、ここに基本的なルール、当社および会員の権利義務、注意事項などを定めた規約 (以下「本規約」といいます。) を公開します。</Text>
                    <Text className={styles.content}>本規約は、本サービスを利用するための契約内容となりますので、利用者は、あらかじめ本規約をお読みいただき、内容をご確認ください。なお、本サービスの会員登録の申請をした時点で、利用者は、本規約に同意したものとみなします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１条 (用語の定義) </Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>本規約において使用する用語の定義は、次のとおりです。このほか、各条項の中で用語を定義している場合があります。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「利用者」とは、本サービスを利用する者、法人その他の団体を意味します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「会員」とは、利用者のうち、会員登録を終えて、本サービスの会員資格 (アカウント) を有する者を意味します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「登録企業」とは、本サービス上で役務の発注を行う会員たる国内企業を意味します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「登録学生団体」とは、登録企業から受注した役務を遂行する会員たる国外の学生団体を意味します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「登録キャリアセンター」とは、登録企業から受注した役務 (業務) を遂行する会員たる国外のキャリアセンターを意味します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「取引手数料」とは、登録企業が大学等に手数料等を支払った場合において、当社に対して負担する手数料 (支払った手数料のうち15%にあたる金額) を意味します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「有料プラン」とは、登録企業が本サービス利用の対価として当社に支払う利用料金に関するもので、サービス内容等に応じて区分された料金体系を意味します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・「投稿データ」とは、会員が本サービス上に投稿あるいは送信する写真・画像・文等を意味します。</Text>
                    <Text className={`${styles.content}`}>・「当社ウェブサイト」とは、当社が運営し、かつ、「Jelper Club」に関係したウェブサイトを意味します。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第２条 (本サービスについて)</Text>
                    <Text className={`${styles.content} `}>本サービスは、国外の大学生の採用やその採用を行う母集団の形成を主たる目的として、国外の大学生、学生団体等および企業をマッチングさせるものであり、その他、会員同士で連絡ができる機能 (チャット等) の提供、本サービスに関連した資料の作成、登録企業に代わり発注を行う行為など、主たる目的に関連した各役務を提供します。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第３条 (会員登録)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1.本サービスを利用しようとする者 (以下「利用希望者」といいます。) は、本規約に同意し、かつ、当社の定める情報を当社に提供することにより、本サービスの会員登録の申請をすることができます。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>2.前項の申請を行った者に対し、当社は、登録完了の旨の通知を送信し、または登録完了の表示をします。当該通知があった時、または当該表示があった時に、会員登録は完了したものとします。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>3.前項の登録完了があった時点で、本サービスの利用契約が、利用希望者と当社の間に成立し、利用者は本サービスの会員となります。</Text>
                    <Text className={`${styles.content}`}>4.当社は、会員登録の際に明らかに誤りのある情報を提供した利用希望者に対し、真正の情報を提供することを依頼し、または登録の取消、拒否をすることができます。</Text>


                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第４条 (会員情報の変更)</Text>
                    <Text className={`${styles.content} `}>会員登録の際に提供した情報に変更があるときは、当該会員は、当社の定める方法により変更後の情報を当社に提供するものとします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第５条 (有料プラン)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>有料プランの料金 (税込) の概要は、企業側に別途送付する資料の通りです。</Text>
                    <Text className={`${styles.content} `}>登録企業は、当社に対し、本サービス利用の対価として、所定の利用料を支払うものとします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第６条 (利用料)</Text>
                    <Text className={`${styles.content} `}>当社は、本サービスのうち当社が行う発注の代行および資料の作成について、その役務を受けた会員に対し、所定のサービス利用料を請求することができます。この利用料の詳細については、当社ウエブサイトに表示します。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第７条 (手数料)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1. 登録企業は、本サービス所定の利用料のほかに、登録学生団体または登録キャリアセンターに発注をしたときは、登録学生団体または登録キャリアセンターが指定する業務ごとの料金と、その料金合計に付随する手数料を負担するものとします。</Text>
                    <Text className={`${styles.content} `}>2.登録企業は、前項の、業務ごとに登録学生団体または登録キャリアセンターに支払う料金合計のうち15%にあたる金額を取引手数料として当社に支払うものとします。これらの手数料の変更については、当社ウェブサイトや規約の変更等でお知らせします。</Text>


                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第８条 (無料体験)</Text>
                    <Text className={`${styles.content} `}>登録企業については、登録完了から1ヶ月の間、1回に限り、本サービスを無償で利用することができます。なお、この期間中に退会しなかったときは、有料プランの利用に切り替わるものとします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第９条 (会員情報などの管理等)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1.利用者は、1人または1学生団体・1キャリアセンター・1法人につき1つのアカウント (会員の資格を意味します。) を保有するものとし、複数のアカウントを作成することはできません。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>2.会員は、自己の責任において、本サービスに関するアカウント、パスワードおよびユーザーIDを適切に管理および保管するものとします。パスワードおよびユーザーID を第三者に利用させ、貸与または譲渡をしてはなりません。</Text>
                    <Text className={`${styles.content} `}>3.パスワードおよびユーザーIDを第三者に使用されたことによって会員に生じた損害について、当社は責任を負いません。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１０条 (決済等)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1.利用料および手数料の決済は、当社が提携する決済サービス「Stripe」( URL:<Link href="https://stripe.com/jp" isExternal>https://stripe.com/jp</Link> ) により行います。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>2.前項のStripeを利用する際は、登録企業は、所定の決済情報 (クレジットカード等の情報) を、登録学生団体および登録キャリアセンターは銀行口座を、それぞれStripeに提供するものとします。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>3.登録企業の第1項にかかる支払い義務の発生後60日以内に、登録学生団体または登録キャリアセンターがStripe上に設けられた専用口座 (以下「Stripe口座」とします。) から自己の銀行口座に入金をしなかったときは、その銀行口座に自動的に入金処理がなされます。</Text>
                    <Text className={`${styles.content} `}>4. 登録学生団体または登録キャリアセンターは、Stripeの定める額に達しない金額を自己の銀行口座に入金するときは、所定の手数料を支払うものとします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１１条 (禁止事項)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>当社は会員に対し、以下に掲げる行為を禁止します。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>下記行為が判明したときは、当社内で協議の上、本サービスの利用停止や会員資格の停止あるいは取消、損害賠償請求を行う場合があります。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・法令 (海外の法令等を含みます) に違反する行為または犯罪行為に関連する行為</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・他の会員の肖像権を侵害する行為</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・公序良俗に反する行為</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・登録企業が、本サービス上であるか否かに関わらず、他の登録企業に営業活動を行うこと</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・本サービス上ではなく、外部の連絡手段を用い、または用いようとする行為 (例示として、プロフィールページやサービス内容、チャット等にメールアドレスを記載する行為や、投稿する画像・写真にQRコードを掲載すること。)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・発注した業務以外の業務を、チャット等で依頼すること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・他社等のウェブサイト、サービスの登録フォームへ誘導し、会員登録、または個人情報を登録させる行為(※個人アカウントに対し、応募職種欄から企業への就職を目的としたアプライをすることは可能です)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・登録企業が、登録学生団体または登録キャリアセンターとしてアカウントを作成し、または登録学生団体または登録キャリアセンターが、登録企業としてアカウントを作成すること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・本サービスで用意された決済方法を利用しないで、外部のサービスを用いて決済を行うこと、成果報酬や月額払い等の当社が定めた方法とは異なる支払方法を他会員に促したり、取り決めしたりする行為</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・自己の業務を遂行していない、または遂行してもそれが不十分な段階で、一方的に業務を終了すること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・成約された業務を正当な理由なく繰り返しキャンセルすること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・登録学生団体、登録キャリアセンターが、業務をキャンセルする意向があるにもかかわらず、成約された業務をキャンセルしないまま、業務を実施すること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・業務完了後、利用料の支払いを60日以上延滞すること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・マネーロンダリングを目的とする行為</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・登録企業が、外部のサービスの他会員の個人アカウントに、本サービス上で発注するような業務を依頼すること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・複数のアカウントを作ること</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・登録学生団体および登録キャリアセンターが、それぞれの所属大学のポリシーに反する行為を行うこと</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・１８歳未満の者が会員登録をすること</Text>
                    <Text className={`${styles.content} `}>・その他、当社が本サービスの利用上、著しく不適切、不適当であると認めた行為</Text>


                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１２条 (本サービスの停止等)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1.当社は、以下に掲げる事由が起こったときは、本サービスの全部または一部の提供を停止または中断することができるものとします。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・天災等の不可抗力事象により本サービスを提供できないとき</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・サーバー、コンピュータ、通信回線等が事故その他の障害により停止または作動しないとき</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・本サービスにかかるコンピュータシステムの保守等を緊急に行う必要があるとき</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・その他、本サービスの停止または中断をすることがやむを得ないと認められるとき</Text>
                    <Text className={`${styles.content} `}>2. 当社は、前項に掲げた事由に基づいて利用者に生じた損害については、その責任を負いません。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１３条 (権利帰属)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1.当社ウェブサイト・本サービスに関する知的財産権は全て当社に帰属します。</Text>
                    <Text className={`${styles.content}`}>2.会員は、投稿データについて、自らが投稿その他送信することについて適法な権利を有していること、
                        および投稿データが第三者の権利を侵害していないことを当社に対し表明し、保証するものとします。</Text>


                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１４条 (登録抹消等)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>当社は、会員に以下のいずれかの事由があった場合は、当該会員に対し、事前に通知または催告することなく、投稿データの削除、当該会員に対する本サービスの利用の停止、会員登録の抹消、および本
                        サービス利用契約の解除の一部または全部の措置(以下「これらの措置」といいます。)を取ることができます。なお、これらの措置を取ったことによる会員に生じた損害について、当社は責任を負いません。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・本規約のいずれかの条項に違反した場合</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・登録した情報や業務内容に虚偽の事実があることが判明した場合</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>・故意または重過失により、第１１条に定める禁止行為を行った場合</Text>
                    <Text className={`${styles.content} `}>・その他、当社がこれらの措置を取ることがやむを得ないと判断した場合</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１５条 (退会)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>会員は、いつでも退会することができます。ただし、決済が完了していないとき、または登録学生団体、および登録キャリアセンターが発注にかかる役務を履行していないときは、この限りではありません。</Text>
                    <Text className={`${styles.content} `}>なお、退会処理が完了した場合であっても、会員は未履行の役務および支払債務を免れることはできません。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第１６条 (本サービスの変更、終了) </Text>
                    <Text className={`${styles.content} `}>当社は、本サービスの内容を変更し、あるいは本サービスを終了させることができます。かかる場合は、変更または終了前に、当社ウェブサイト等にその旨を掲示するか、または会員に対し通知をします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}> 第１７条 (保証の否認および免責)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1.当社は、本サービスが利用者の特定の目的に適合することや正確性・有用性等を有することを保証するものではありません。 </Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>2.当社は、本サービスの提供の中断、停止、終了、利用不能または変更、利用者が本サービス上で送信したメッセージまたは情報の削除または消失、会員資格の停止、抹消、本サービスの利用による登録情報の消失または機器の故障もしくは損傷によって利用者が被った損害について、その責任を負わないものとします。ただし、当社が故意または重過失により、その損害を招いたときは、この限りではありません。 </Text>
                    <Text className={`${styles.content} `}> 3.当社は利用者間または利用者・第三者間で、役務の提供等に関して生じた紛争について、その責任を負わないものとします。</Text>


                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}> 第１８条 (秘密保持)</Text>
                    <Text className={`${styles.content} `}>利用者は、本サービスに関連して当社が利用者に対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面または電磁的記録による承諾がある場合を除き、秘密に取り扱う義務があるものとします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}> 第１９条 (データ等の取扱い)</Text>
                    <Text className={`${styles.content} `}>利用者の個人情報等のデータに関する取扱いについては、当社が別途定めるプライバシーポリシーに
                        よるものとします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}> 第２０条 (本規約の変更)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}> 1.当社は、本サービスの変更や関連法令の改正等に伴い、本規約を変更できるものとします。当社が本規約を変更したときは、当社が公開する利用規約を改定し、その旨を表示します。</Text>
                    <Text className={`${styles.content} `}>2.前項の場合において、利用者が、相当の期間、本サービスの利用を続けるときは、変更後の規約に同意したものとみなします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第２１条 (連絡・通知等) </Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>1.当社と利用者間における連絡は、原則として、電子メールで行い、当社から利用者に対するお知らせは、原則として、当社ウェブサイトまたは当社が提供するアプリケーション上で行います。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>2.当社が提供するアプリケーションには、通知を停止できる機能はありません。よって、利用者は、当社からの通知をアプリケーション上では、停止することはできません。 </Text>
                    <Text className={`${styles.content} `}>3.利用者は、メール配信の停止を希望する場合、メールアドレス: <Link href="mailto:info@jelper.co" isExternal>info@jelper.co</Link> 宛てに、アカウント名および中断したい旨を記載の上、メールするものとします。</Text>


                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}> 第２２条 (会員資格の譲渡等)</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}> 1.会員は、当社の事前の承諾を得ることなく、その会員資格または本規約に基づく権利もしくは義務を第三者に譲渡、移転、担保設定等をすることはできません。</Text>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>2.当社は、本サービスの事業譲渡があった場合、会員資格、本規約に基づく権利および義務並びに会員の登録情報その他の利用者の顧客情報を譲受人に譲渡、提供することができるものとし、利用者は、かかる譲渡、提供につき予め同意するものとします。 </Text>
                    <Text className={`${styles.content} `}>会社分割、その他本サービスにかかる事業が承継される場合も、同様とします。</Text>

                    <Text className={`${styles.contentTitle} ${styles.noMarginBottom}`}>第２３条(準拠法および管轄裁判所) </Text>
                    <Text className={`${styles.content} `}>本規約は、日本法に準拠しています。本サービスに関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</Text>

                    <Text className={`${styles.content} ${styles.noMarginBottom} ${styles.underLine}`}> 2023年6月20日 制定</Text>
                </Box>
            </Center>

            <Center>
                <Text className={styles.title}> Terms and Conditions</Text>
            </Center>

            <Center>
                <Box>
                    <Text className={`${styles.contentEn} `}>Jelper Club Inc. (hereinafter referred to as “we” or "the Company") offers services (hereinafter referred to as "this Service") under the name of "Jelper Club," which act as a bridge between domestic corporations and overseas students, student organizations, career centers, and the like. Here we publish the Terms and Conditions (hereinafter referred to as "these Terms and Conditions") that stipulate the basic rules, rights and obligations of the Company and its members, precautions, etc. regarding this Service.</Text>

                    <Text className={`${styles.contentEn} `}>These Terms and Conditions constitute the agreement for using this Service, so please read these Terms
                        and Conditions in advance and confirm their contents. When you apply for membership registration for
                        this Service, you are considered to have agreed to these Terms and Conditions.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 1 (Definition of Terms) </Text>
                    <Text className={`${styles.contentEn} `}>The definitions of terms used in these Terms and Conditions are as follows. There may also be cases
                        where terms are defined within each clause.</Text>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>"User" refers to those who use this Service, including corporations and other organizations. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> "Member" refers to Users who have completed member registration and hold the membership
                        qualification (account) for this Service.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> "Registered Company" refers to domestic corporations that place orders for services on this Service and
                        are Members.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>"Registered Student Organization" refers to overseas student organizations that are Members and carry
                        out the services ordered from the Registered Companies. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>"Registered Career Center" refers to overseas career centers that are Members and carry out the services
                        (duties) ordered from the Registered Companies. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>"Transaction Fee" refers to the fee (15% of the paid fee) that the Registered Company bears for the
                        Company when it pays fees to the university or the like. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>"Paid Plan" refers to the fee system categorized according to service content etc., which the Registered
                        Company pays to the Company as the price for using this Service. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>"Posted Data" refers to photos, images, texts, etc., posted or sent by the Members on this Service. </Text>
                    <Text className={`${styles.contentEn} `}>"Company Website" refers to the website operated by the Company related to "Jelper Club".</Text>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 2 (About this Service) </Text>
                    <Text className={`${styles.contentEn} `}>The main purpose of this Service is to recruit overseas university students and form the pool of potential
                        recruits, matching overseas university students, student organizations, etc. and corporations. In addition,
                        it provides various services related to its main purpose, such as enabling contact between members (chat,
                        etc.), creating documents related to this Service, and acting on behalf of Registered Companies to place
                        orders.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 3 (Member Registration) </Text>

                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList}`}>
                        <OrderedList>
                            <ListItem>Those who wish to use this Service (hereinafter referred to as "Applicants") can apply for
                                member registration of this Service by agreeing to these Terms and Conditions and providing
                                information specified by the Company.</ListItem>
                            <ListItem>For those who have applied in the preceding paragraph, the Company will send a notification of
                                registration completion or display that the registration has been completed. When such
                                notification or display is made, the member registration is considered complete.</ListItem>
                            <ListItem>When the registration is completed as stated in the preceding paragraph, a usage contract for
                                this Service is established between the Applicant and the Company, and the Applicant becomes a
                                member of this Service.</ListItem>
                            <ListItem>The Company may ask Applicants who have provided clearly erroneous information at the time of
                                member registration to provide accurate information, or may cancel or refuse registration.</ListItem>
                        </OrderedList>
                    </Box>



                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 4 (Change of Member Information) </Text>
                    <Text className={`${styles.contentEn} `}>When there is a change in the information provided at the time of member registration, the Member shall
                        provide the updated information to the Company using the method specified by the Company.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 5 (Paid Plans) </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>The details of the pricing for the paid plan, inclusive of taxes, are as outlined in the separate documentation provided to the corporate clients. Registered companies shall pay to us the stipulated service fees as consideration for the use of the services.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 6 (Usage Fee) </Text>
                    <Text className={`${styles.contentEn} `}>The Company may charge the members who received the service of acting on behalf of the Registered
                        Company to place orders and creating documents with a predetermined service fee. The details of this
                        usage fee will be displayed on the Company's Website.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 7 (Fees) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>In addition to the prescribed usage fee for this Service, Registered Companies, when placing
                                orders to Registered Student Organizations or Registered Career Centers, shall bear the fee per
                                task specified by the Registered Student Organization or Registered Career Center and the
                                associated fees for the total fee.</ListItem>
                            <ListItem>Registered Companies shall pay to the Company a transaction fee amounting to 15% of the total
                                fees paid to the Registered Student Organization or Registered Career Center per task. Changes to
                                these fees will be announced through changes to the Company's Website or the Terms and
                                Conditions.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}> Article 8 (Free Trial)</Text>
                    <Text className={`${styles.contentEn} `}>Registered companies may utilize this service free of charge for one month, once only, from the
                        completion of registration. If the user does not cancel within this period, they will be switched to a paid
                        plan.</Text>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 9 (Management of Member Information, etc.) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>Each user, whether individual, student organization, career center, or corporation, is allowed to
                                hold one account (which signifies membership), and cannot create multiple accounts.</ListItem>
                            <ListItem>Members shall appropriately manage and store their account, password, and user ID related to
                                this service at their own responsibility. You must not allow a third party to use, lend, or transfer
                                your password and user ID.</ListItem>
                            <ListItem>Our company bears no responsibility for any damage incurred by the member due to the use of
                                their password and user ID by a third party.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 10 (Payment, etc.) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>The settlement of usage fees and service charges will be conducted through our affiliated
                                payment service 'Stripe' (URL:https://stripe.com/jp).</ListItem>
                            <ListItem>When using Stripe, registered companies will provide predetermined payment information (such
                                as credit card information) to Stripe, while registered student organizations and career centers
                                will provide their bank account information.</ListItem>
                            <ListItem>If, within 60 days after the obligation to pay as per section 1 arises, a registered student
                                organization or career center does not deposit into their bank account from the dedicated
                                account (hereinafter referred to as the "Stripe account") set up on Stripe, an automatic deposit
                                process will be made to that bank account.</ListItem>
                            <ListItem>When a registered student organization or career center deposits an amount that does not reach
                                the amount set by Stripe into their own bank account, they shall pay a predetermined fee.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 11 (Prohibited Acts) </Text>
                    <Text className={`${styles.contentEn} `}>We prohibit members from engaging in the acts listed below. If such acts are identified, we may, after
                        internal consultation, suspend use of the service, suspend or cancel membership, and claim damages.</Text>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> Acts that violate laws (including overseas laws) or are related to criminal activities.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Acts that infringe on the portrait rights of other members. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Acts that are contrary to public order and morals. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Registered companies conducting sales activities to other registered companies, whether on this service
                        or not. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>The act of using, or trying to use, an external method of contact not on this service (for example, listing an
                        email address in a profile page, service content, chat, etc., or posting a QR code in uploaded images or
                        photos). </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> Asking for work other than the work ordered through chat, etc.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>The act of directing members to the registration form of other companies' websites or services, making
                        them register as members, or having them register personal information (※ Personal accounts can apply
                        for jobs aimed at companies from the job application field). </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Registered companies creating an account as a registered student organization or career center, or vice
                        versa. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Not using the payment methods provided by this service, using external services for payment,
                        encouraging other members to adopt different payment methods than those established by our company,
                        or agreeing on such methods. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> Unilaterally terminating work without completing your own work, or while it is still insufficient.
                        Repeatedly canceling contracted work without a valid reason.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> Registered student organizations or career centers conducting work despite having the intention to cancel
                        contracted work, without canceling it.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Delays in paying the usage fee for more than 60 days after work completion. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> Acts aimed at money laundering.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Registered companies requesting work orders on personal accounts of other members of external
                        services that would normally be ordered on this service. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Creating multiple accounts. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> Registered student organizations and career centers conducting activities that violate the policies of their
                        respective universities.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>Registration of membership by persons under 18 years old. </Text>
                    <Text className={`${styles.contentEn} `}>Any other acts that we deem significantly inappropriate or unsuitable for the use of this service. </Text>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 12 (Suspension of this Service, etc.) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>
                                We may suspend or interrupt all or part of this service under the following circumstances:
                                <UnorderedList>
                                    <ListItem>When it is impossible to provide this service due to force majeure events such as natural
                                        disasters.</ListItem>
                                    <ListItem>When the server, computer, communication line, etc. are stopped or do not operate due to
                                        accidents or other failures.</ListItem>
                                    <ListItem>When urgent maintenance of the computer system related to this service is needed.</ListItem>
                                    <ListItem>In any other circumstances where it is deemed unavoidable to suspend or interrupt this
                                        service.</ListItem>
                                </UnorderedList>
                            </ListItem>
                            <ListItem>We bear no responsibility for damages caused to the user based on the circumstances listed in
                                the preceding paragraph.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 13 (Ownership of Rights) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>
                                All intellectual property rights related to our website and this service belong to our company.
                            </ListItem>
                            <ListItem>Members shall represent and warrant to our company that they have the lawful rights to post or
                                otherwise transmit the posted data, and that the posted data does not infringe the rights of third
                                parties.</ListItem>
                        </OrderedList>
                    </Box>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 14 (Deregistration, etc.) </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> If any of the following events occur to a member, we may, without prior notice or demand to the member,
                        take measures such as deleting posted data, suspending the use of this service for the member, erasing
                        membership registration, and cancelling all or part of this service use contract (hereinafter referred to as
                        "these measures"). We bear no responsibility for any damage incurred by the member as a result of these
                        measures.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>・If the member violates any provision of these Terms and Conditions. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>・If it is found that there is a false fact in the registered information or the content of the work. </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> ・If payment is stopped or becomes insolvent, or if a petition is filed for the commencement of bankruptcy
                        proceedings, civil rehabilitation proceedings, corporate rehabilitation proceedings, special liquidation, or
                        any similar proceedings.</Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}>・If you intentionally or by gross negligence commit the prohibited acts outlined in Article 11</Text>
                    <Text className={`${styles.contentEn} `}>・In other situations where we determine that it is indispensable to take these measures</Text>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 15 (Resignation) </Text>
                    <Text className={`${styles.contentEn} ${styles.noMarginBottom}`}> Members have the right to withdraw at any time. However, this is not the case if the payment is not
                        completed, or if the registered student group and the registered career center have not fulfilled their
                        service obligations.</Text>
                    <Text className={`${styles.contentEn} `}>Even upon the completion of the withdrawal process, members cannot absolve themselves from the
                        responsibility of fulfilling outstanding obligations or payment debts.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 16 (Changes and Termination of the Service) </Text>
                    <Text className={`${styles.contentEn} `}>We may change the contents of this service or terminate this service. In such cases, we will post or notify
                        our members on our website, etc. before the changes or termination.</Text>


                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 17 (Denial of Warranty and Exemption) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>
                                We do not guarantee that this service is suitable for a particular purpose of the user or that it is
                                accurate and useful.
                            </ListItem>
                            <ListItem>We shall not be liable for any damages suffered by the user due to the interruption, suspension,
                                termination, unavailability or alteration of this service, the deletion or loss of messages or
                                information sent by the user on this service, the suspension or cancellation of membership, the
                                loss of registered information due to the use of this service, or the failure or damage of
                                equipment. However, this does not apply when we have caused the damage intentionally or by
                                gross negligence.</ListItem>
                            <ListItem>We shall not be liable for any disputes arising between users or between users and third parties
                                in relation to the provision of services.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}> Article 18 (Confidentiality)</Text>
                    <Text className={`${styles.contentEn} `}>Users are obliged to handle non-public information disclosed by us to the user in relation to this service
                        confidentially, unless we have given prior consent in writing or in electronic records.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}> Article 19 (Handling of Data)</Text>
                    <Text className={`${styles.contentEn} `}>The handling of users' personal information and other data shall be governed by our separately defined
                        privacy policy.</Text>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 20 (Amendment of these Terms and Conditions) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>
                                We may change these Terms and Conditions in accordance with changes in the service or
                                amendments to related laws, etc. When we change these Terms and Conditions, we will revise
                                the Terms and Conditions of use that we disclose and display the fact of the amendment.
                            </ListItem>
                            <ListItem>In the event of the preceding paragraph, if a user continues to use this service for a reasonable
                                period of time, it shall be deemed that the user has agreed to the amended Terms and
                                Conditions.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 21 (Contact and Notification, etc.) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>
                                Communication between us and the users shall be mainly by e-mail, and notifications from us to
                                the users shall be mainly on our website or applications provided by us.
                            </ListItem>
                            <ListItem>There is no feature to stop notifications in the application provided by us. Therefore, users cannot
                                stop notifications from us on the application.</ListItem>
                            <ListItem>If a user wishes to stop receiving emails, the user should email to info@jelper.co stating the
                                account name and the desire to stop.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 22 (Transfer of Membership) </Text>
                    <Box width="65%" margin="auto" mb={10} className={`${styles.contentOrderList} `}>
                        <OrderedList>
                            <ListItem>
                                Members cannot transfer, move, or secure their membership or rights or obligations under these
                                Terms and Conditions to a third party without our prior consent.
                            </ListItem>
                            <ListItem>We may transfer, provide the membership, rights and obligations under these Terms and
                                Conditions, registration information of the member, and other customer information of the user
                                to the successor in case of business transfer of this service, and users agree to such transfer and
                                provision in advance. The same shall apply in the case of company splits and other successions of
                                the business related to this service.</ListItem>
                        </OrderedList>
                    </Box>

                    <Text className={`${styles.contentEn} ${styles.noMarginBottom} ${styles.contentHead}`}>Article 23 (Governing Law and Jurisdiction) </Text>
                    <Text className={`${styles.contentEn} `}>These Terms and Conditions are governed by Japanese law. All disputes related to this service will be
                        under the exclusive jurisdiction of the Tokyo District Court in the first instance.</Text>

                    <Text className={`${styles.contentEn}  ${styles.contentHead}`}> Enacted on June 20, 2023</Text>

                </Box>
            </Center >

            <Footer />
        </>
    )
}


export default TermsAndConditions;