import {
    Text, Link,
    Center, Box, Table, Tbody, Tr, Td
} from "@chakra-ui/react";

// css
import styles from "../css/PrivacyPolicy.module.css";

import { Navbar } from "./Navbar";

function PrivacyPolicy () {
    return  (
        <>
            <Navbar />
            <Center>
                <Text className={styles.title}>プライバシーポリシー</Text>
            </Center>

            <Center>
                <Box>
                    <Text className={`${styles.content} ${styles.noMarginBottom}`}>株式会社Jelper Club  (以下「当社」といいます。)は、採用、採用母集団の形成を主たる目的として、世界に所在する大学の学生、日本関連の学生団体・キャリアセンターと日本企業のマッチングプラットフォームを運営しております。</Text>
                    <Text className={`${styles.content} `} mb={5}>このプラットフォームや事業運営の上で、多くのお客様及び関係者の皆様の個人情報を取り扱うこととなるため、ここにプライバシーポリシー (以下「本ポリシー」といいます。)を定め、その管理体制を確立し、個人情報関連の法令を遵守します。</Text>

                    <Text className={`${styles.content}  ${styles.fontBold}`} mb={5}>個人情報保護方針一覧</Text>
                    <Text className={`${styles.content}`} mb={5}>方針1. 取り扱う個人情報の利用目的を特定し、その目的達成に必要かつ相当な範囲内で適切に取り扱いをします。</Text>
                    <Text className={`${styles.content}`} mb={5}>方針2. 個人情報を適法かつ相当な方法で取得します。</Text>
                    <Text className={`${styles.content}`} mb={5}>方針3. 個人情報を、本人の同意なくして、第三者に提供しません。</Text>
                    <Text className={`${styles.content}`} mb={5}>方針4. 個人情報の漏洩・滅失・毀損の防止及び是正その他の安全管理のために必要かつ適切な措置を講じるよう努めます。</Text>
                    <Text className={`${styles.content}`} mb={5}>方針5. 個人情報の取扱い方法を、常に最新の関連法令に準拠させ、正確な取扱いに努めます。</Text>
                    <Text className={`${styles.content}`} mb={5}>方針6. 個人情報保護に関する苦情及び相談に対応する窓口を設けて、適切に対応するよう努めます。 </Text>
                    <Text className={`${styles.content}`}> 株式会社Jelper Club</Text>
                    <Text className={`${styles.content}`} mb={5}> 代表取締役 光澤大智  (Daichi Mitsuzawa)</Text>

                    <Text className={`${styles.content}  ${styles.fontBold}`}>個人情報</Text>
                    <Text className={`${styles.content}`} mb={10}>個人情報とは、当社の提供するプラットフォームの利用者 (以下「ユーザー」といいます。)個人に関する情報であって、当該情報を構成する氏名、住所、電話番号、メールアドレス、大学名その他の情報及びデータ等により当該ユーザーを識別できるものをいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的に利用者個人を識別できるものも個人情報に含まれます。なお、本ポリシーで使用する用語の定義は、個人情報の保護に関する法律 (以下「個人情報保護法」といいます。)に準拠します。</Text>

                    <Text className={`${styles.content}  ${styles.fontBold}`} >個人情報の利用目的</Text>
                    <Text className={`${styles.content}`}> 個人情報を当社が利用する目的は、以下に定めるとおりです。</Text>
                    <Text className={`${styles.content}`} mb={5}> これらの目的の範囲を超えて、個人情報を利用することはありません。 </Text>
                    <Text className={`${styles.content}`}>A ユーザーの本人性、属性等を確認するため</Text>
                    <Text className={`${styles.content}`}>B プラットフォーム上で、採用、採用母集団の形成等の目的でマッチングサービスを提供するため</Text>
                    <Text className={`${styles.content}`}>C マーケティング施策を実施するため </Text>
                    <Text className={`${styles.content}`}>D キャンペーン・広報・アンケート・調査を実施するため</Text>
                    <Text className={`${styles.content}`}>E 利用料金を請求するため (決済処理のため) </Text>
                    <Text className={`${styles.content}`}>F 利用規約に抵触する行為を行い、または不正・不当な目的でサービスを利用する者に対してサービスの利用停止等の措置を取るため </Text>
                    <Text className={`${styles.content}`}>G 当社サービスの向上や改善を図るため </Text>
                    <Text className={`${styles.content}`}>H 新規サービスの開発や研究のため</Text>
                    <Text className={`${styles.content}`}>I 求人情報に関するメールマガジンを適切に配信するため</Text>
                    <Text className={`${styles.content}`}>J ユーザーの承諾に基づき、会員登録時に入力いただいた情報を、当社のサービスに登録している企業（以下「参画企業」といいます）に提供するため</Text>
                    <Text className={`${styles.content}`}>K 当社および当社サービスを紹介するための参考資料として、個人を特定できないよう加工した情報を取引先企業に開示するため</Text>
                    <Text className={`${styles.content} `} mb={10}>L ユーザーやウェブサイト閲覧者等に対する問い合わせ対応や諸連絡のため</Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}>個人情報利用目的の変更 </Text>
                    <Text className={`${styles.content}`} mb={5}>当社は、個人情報の利用目的について、上に定めた目的と類似するなど相当の関連性を有する範囲内、あるいは合理性の認められる範囲内において、変更をします。</Text>
                    <Text className={`${styles.content}`} mb={5}>利用目的を変更したときは、当該変更された目的について、ユーザーやウェブサイト閲覧者に対して本ポリシーにて、お知らせします。</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}>取得データについて</Text>

                    <Text className={`${styles.content}`} mb={5}>当社が取得する、または、取得する可能性のある情報は下記のとおりです。取得、取得可能性のある情報が、上記「個人情報」に該当する場合には、本ポリシーが適用されるものとします。 </Text>


                    <Box width="70%" margin="auto" mb={5}>
                        <Table variant="simple" size="md" border="2px" borderColor="black">
                            <Tbody className={`${styles.content}`}>
                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold} `}>・学生に係る情報</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}>氏名 / 生年月日 / 住所 / メールアドレス / パスワード / 大学に関する情報 ( 大学名・学位・専攻・成績スコア/卒業年 (見込み含む) )</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold}`}>・学生団体に係る情報</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}>団体名 / 大学名 / 電話番号 / 所在地 / メールアドレス / パスワード / ウェブサイトのURL / 有料サービス利用時の決済情報</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold}`}>・キャリアセンターに係る情報</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}>キャリアセンター名 / 大学名 / 電話番号 / 所在地 / メールアドレス / パスワード / ウェブサイトのURL / 有料サービス利用時の決済情報</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold}`}>・企業に係る情報</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}>企業名 / 電話番号 / 業種 / 本店所在地 / メールアドレス / パスワード / ウェブサイトのURL / 利用プラン / 有料サービス利用時の決済情報</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>

                    <Text className={`${styles.content}`} mb={10}>その他、ユーザーのサービスの利用内容や状況、プロフィール画像や写真なども取得します。なお、当社がアプリケーションをリリースしたときは、当該アプリケーションを通じても、情報を取得します。</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >ユーザーの個人情報提供について </Text>
                    <Text className={`${styles.content}`} mb={10}>当社は、上記「個人情報の利用目的」の達成に必要な限度で、ユーザーに個人情報を提供していただきます。特定の事項に関し、情報を提供いただけない場合は、当社のサービスの全部または一部を利用することができない場合がありますので、あらかじめご了承ください。  </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >安全管理措置 </Text>
                    <Text className={`${styles.content}`} mb={5}> 当社は、取り扱う個人情報等の漏えい、滅失等を防ぐため、安全管理のための措置を講じます。この安全管理措置には、次に掲げる事項が含まれます。</Text>


                    <Text className={`${styles.content}`}>A 個人情報等の取扱いに関する責任者・管理者を設置すること </Text>
                    <Text className={`${styles.content}`}>B 情報を盗み取るなど不正な目的をもって外部から侵入する行為、不正なアクセスから個人情報を保護するセキュリティ対策をすること</Text>
                    <Text className={`${styles.content}`}>C 当社の内部の者のうち、個人情報を閲覧する権限を持たない者による閲覧を防止すること </Text>
                    <Text className={`${styles.content}`}>D 個人情報を取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止すること</Text>
                    <Text className={`${styles.content}`} mb={10}>E 従業者に対する個人情報の取扱いや保護に関する教育の実施や監督 </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`} >   第三者提供と共用</Text>
                    <Text className={`${styles.content}`} mb={5}>当社は、以下に定める合理的な理由がある場合を除いて、ユーザー本人の同意を得ずに、その個人情報を第三者に提供しません。また、個人情報を第三者と共同使用する場合でも、その利用目的は「個人情報の利用目的」の範囲内に限ります。 </Text>
                    <Text className={`${styles.content}`}>A 決済サービス「Stripe」を使用する場合  (銀行口座やクレジットカードその他決済に関連する情報のみが対象です。)</Text>
                    <Text className={`${styles.content}`}>B ユーザー本人が、第三者への開示または提供を求めた場合 </Text>
                    <Text className={`${styles.content}`}>C 法令が要求する場合</Text>
                    <Text className={`${styles.content}`} mb={10}>D 組織再編や事業譲渡等があった場合で、当社と第三者が登場する場合</Text>

                    <Text className={`${styles.content} ${styles.fontBold}`} > 個人情報処理の外部委託</Text>
                    <Text className={`${styles.content}`} > 当社は、個人情報を取り扱う業務の一部または全部を外部委託することがあります。</Text>
                    <Text className={`${styles.content}`} mb={10} >この場合において、委託先の個人情報の取扱いに関しては、当社がその責任を負うとともに、委託先において個人情報の安全管理が行われるよう計らいます。  </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`} >利用目的通知等の請求  </Text>
                    <Text className={`${styles.content}`}>A 利用目的の通知又は個人情報若しくは第三者提供記録の開示</Text>
                    <Text className={`${styles.content}`} mb={5} >ユーザーやこれから当社サービス及びプラットフォームを利用しようとする者 (以下この項目において「ユーザー等」という)は、正当な目的の範囲内で、利用目的の通知を求め、又は、個人情報若しくは第三者提供記録の開示を請求することができます。ただし、開示することが法令に違反する場合や当社が極めて不適当と判断した場合には、この限りではありません。  </Text>

                    <Text className={`${styles.content}`}>B 訂正等</Text>
                    <Text className={`${styles.content}`} mb={5}>ユーザー等は、個人情報の訂正・追加・削除 (以下「訂正等」といいます。)を請求することができます。この場合において、請求に合理的な理由があるときは、当該個人情報の訂正等を行います。</Text>

                    <Text className={`${styles.content}`}>C 利用停止又は第三者提供の停止</Text>
                    <Text className={`${styles.content}`} mb={5}>ユーザー等は、個人情報の利用の停止又は第三者提供の停止を請求することができます。当該請求があったときは、権利の濫用等と認められない限り、すみやかに当該情報の使用を停止しまたは、第三者への提供を停止します。</Text>

                    <Text className={`${styles.content}`} mb={5}> 上記請求は、ユーザー本人のみならず、その任意代理人・法定代理人によることもできます。※本人から請求があったときは、当社の責任者または管理者が本人確認を行い、代理人から請求があったときは、代理人の資格を確認させていただきます。</Text>

                    <Text className={`${styles.content}`} >Cookieについて  </Text>
                    <Text className={`${styles.content}`} >当社のウェブサイトでは、Cookie  (クッキー: ウェブサイト提供者がユーザーの利用するコンピュータを識別する目的で、サーバからユーザーのブラウザに送信され、ユーザーのコンピュータに蓄積される情報のことをいいます。) を使用する可能性があります。  </Text>
                    <Text className={`${styles.content}`} >基本的に、Cookie自体では個人情報を構成しませんが、上記「個人情報」の項目で述べたとおり、個人情報と一体となることで、特定の個人を識別することができるときは、Cookie情報も個人情報になるものとします。</Text>

                    <Text className={`${styles.content}`} mb={5} >※Cookieを使用することにより、お客様の端末情報、位置情報、行動履歴などを取得します。ただし、Cookieを使用する場合でも、当該使用だけではユーザー個人を特定できる情報  (例: 氏名や住所、電話番号等) を取得しません。</Text>
                    <Text className={`${styles.content}`}>取得した情報については、以下の目的に使われます。 </Text>
                    <Text className={`${styles.content}`}>A アカウント作成・会員ページのログインを円滑に行うため (入力補助など)</Text>
                    <Text className={`${styles.content}`}>B ユーザーにとって最適なコンテンツを表示するため</Text>
                    <Text className={`${styles.content}`}>C ウェブサイト改善を目的とした、閲覧状況の統計的な把握のため</Text>
                    <Text className={`${styles.content}`} mb={10}>D その他ユーザーのウェブサイト閲覧等の利便性を図るため </Text>


                    <Text className={`${styles.content} ${styles.fontBold}`} >Googleアナリティクスの使用について</Text>
                    <Text className={`${styles.content}`} > 当社は、Googleが提供するGoogleアナリティクス (ウェブサイトのアクセス解析をおこなうためのサービスであり、ウェブサイトの訪問者の動向等を把握するものをいいます。) を使用する可能性があります。</Text>
                    <Text className={`${styles.content}`} mb={5}>Googleが、当方またはGoogleの設定するクッキーをもとにしてユーザーの閲覧履歴等を収集し、分析した結果を受け取り、利用状況の把握や、当社のサービス等に役立てる可能性があります。GoogleによるGoogleアナリティクスにおけるデータの取扱いについては、下記のウェブサイトをご覧ください。 </Text>
                    <Text className={`${styles.content}`} mb={5} >「GOOGLEのサービスを使用するサイトやアプリから収集した情報のGOOGLEによる使用」 </Text> <Link className={`${styles.content} ${styles.linkColor}`} href="https://policies.google.com/technologies/partner-sites" isExternal>https://policies.google.com/technologies/partner-sites</Link>

                    <Text className={`${styles.content} ${styles.fontBold}`} mt={10}>統計のためのデータの取扱い  </Text>
                    <Text className={`${styles.content}`} >当社は、提供を受けた個人情報をもとにして、個人を特定できないように加工した統計データを作成する可能性があります。当該データについては、当社は何らの制限なく、利用することができるものとします。 </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`} >本ポリシーの変更 </Text>
                    <Text className={`${styles.content}`} mb={5} > 関連法令の改正が施行されたり、改善すべき点を発見したりした場合など、本ポリシーを変更することが相当なときは、当社は本ポリシーを改定して、本ポリシーと一体として、変更部分を公開します。</Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}>お問い合わせ</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}>本ポリシーに関するお問い合わせや利用目的通知等の請求は、下記メールアドレス宛にお願いします。責任者または管理者その他の個人情報を取り扱う部署より、対応させていただきます。</Text>
                    <Text className={`${styles.content}`} mb={10} ><strong>なお</strong>、利用目的の通知又は個人情報若しくは第三者提供記録の開示の請求をおこなおうとするときは、個人情報保護法により手数料をいただく場合があります。</Text>
                    <Link className={`${styles.content} ${styles.linkColor}`} href="mailto:info@jelper.co" isExternal>Mail: info@jelper.co</Link>
                    
                    <Center>
                        <Text className={`${styles.content} ${styles.fontBold}`} mt={20} mb={5} fontSize={22}>特則</Text>
                    </Center>

                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5} >個人データの主体が属する国・地域により、以下に定める条項も追加的に適用されるものとします。</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >[GDPRについて] </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}>EU域内に居住する者の個人データの処理については、GDPR5条に定める次の原則に従い、当該データを取扱います。 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >A 適法性、公正性および透明性の原則</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >B 目的限定の原則</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >C データ最小限の原則  </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >D 正確性の原則  </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >E 保存の制限の原則</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5}>F 完全性および機密性の原則</Text>

                    <Text className={`${styles.content} ${styles.fontBold}`} > 適法要件</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >個人データの処理については、次に定める場合に限り、当該処理を行います。</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >A ユーザーが、データの処理について同意を与えた場合</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} >B 法的義務を遵守するためにデータ処理が必要となる場合 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5}>C その他、正当な利益のためにデータ処理が必要となる場合 (ただし、データの主体の権利・利益が正当な利益を上回る場合を除く。)  </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`} >情報公開</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5} >当社は、個人データを取得する場合には、データの主体に対して特則を含む本ポリシーの全文を公開するものとします。  </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >個人データの保存期間 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={10}>個人データを当社で記録保管する期間は、当該データの帰属するユーザーが当社のプラットフォームを利用することがなくなった時点から相当の期間が経過するまでとします。  </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >ユーザーの権利</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}  >ユーザーは、自己の個人データにアクセスし、訂正や削除を当社に求める権利を有します。</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}  >そのほか、以下に定める権利も有します。 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}  >A 処理制限権利 (個人データを消さずに、その取扱いのみを制限できる権利) </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}  >B 異議申立権 (正当な利益のため、自己の個人データの処理に異議を唱える権利) </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}  >C データポータビリティ権 (個人データを読み取りや出力が可能なデータ形式で自己や他の管理者に引き渡しを求めることができる権利)  </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5} >D プロファイリングに反対する権利 (自己に対する法的効果またはそれと同一の効果を有するような、プロファイリングを含む自動処理のみに基づく判断の対象にならないように求める権利) </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >個人データの受領者 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={10} >個人データを受領することができる者は、当社及び第三者提供することがやむを得ないと客観的に認められる当該第三者とします。</Text>

                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5}>[中国における個人情報保護法及び個人情報保護規範]</Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >個人情報管理者</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5} >個人情報に関する管理者は、当面の間、当社の役員とします。管理者を変更する場合は、本特則を変更するものとします。 </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >個人情報の取得 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}  >当社のプラットフォームの使用を通じてサービスを利用するときは、「取得データについて」の項目における表中にあるユーザーに関する情報を取得します。アカウント作成についても同様とします。</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5} >個人情報の取得経路は、ユーザーから当社への連絡、当社ウェブサイト、アプリケーションを運用するときは当該アプリケーションからとなります。 </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >個人情報の保存期間 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5}> 取得した個人情報については、その保存期間を設けるものとします。ユーザーから削除の依頼請求があった場合はもちろんですが、ユーザーが当社サービスを利用しないと意思表示をしたとき、または引き続き、1年以上サービスを利用しないことが判明したときは、保存した当該個人情報を消去します。</Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >個人情報の第三者提供</Text>
                    <Text className={`${styles.content} ${styles.fontBold}`}  >やむを得ない事由があるとき、または有料のサービスを利用するときは、個人情報を第三者に提供します。 </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={5} >やむを得ない事由とは、日本の法令で第三者提供が要求されている場合、ユーザー本人または第三者に切迫した危険を避ける場合が含まれます。これらの場合には国・地方公共団体、裁判所等に個人情報を提供する場合があります。また、有料サービス利用時は、決済サービス「Stripe」に決済に係る情報を提供します。  </Text>

                    <Text className={`${styles.content} ${styles.fontBold}`}  >個人情報に関する権利  </Text>
                    <Text className={`${styles.content} ${styles.fontBold}`} mb={20}> ユーザーの個人情報に関する権利については、本ポリシー内の利用目的通知請求等など、関連規定に準ずるものとします。</Text>

                    <Text className={`${styles.content} ${styles.fontBold} ${styles.underLine}`}>2023年6月制定</Text>
                </Box>
            </Center >

            <Center>
                <Text className={styles.titleEn}>Privacy Policy</Text>
            </Center>

            <Center>
                <Box>
                    <Text className={`${styles.contentEn}`} mb={5}>Jelper Club Inc.  (hereinafter referred to as “we” or "the Company") primarily operates a matchingplatform for students from universities worldwide, student organizations related to Japan, careercenters, and Japanese companies with the primary objective of recruiting and forming a candidatepool. Due to the operation of this platform and business, we handle a lot of personal informationfrom our customers and related parties. We have established this Privacy Policy  (hereinafterreferred to as "the Policy") to manage this information and comply with laws related to personalinformation.  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} mb={5} >Personal Information Protection Policy List:  </Text>

                    <Text className={`${styles.contentEn}`} mb={5}>Policy 1: We will identify the purpose of using the personal information we handle and manage itappropriately within the necessary and reasonable scope to achieve that purpose.</Text>

                    <Text className={`${styles.contentEn}`} mb={5}>Policy 2: We will obtain personal information in a legal and fair manner.</Text>

                    <Text className={`${styles.contentEn}`} mb={5}>Policy 3: We will not provide personal information to third parties without the consent of theindividual.</Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Policy 4: We will strive to take necessary and appropriate measures to prevent leakage, loss, ordamage of personal information and for other safety management. </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Policy 5: We will constantly strive for accurate handling by complying with the latest related lawsregarding the handling of personal information.  </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Policy 6: We will establish a contact point to appropriately respond to complaints andconsultations regarding personal information protection.  </Text>
                    <Text className={`${styles.contentEn}`} >   Jelper Club Inc.   </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Representative Director: Daichi Mitsuzawa </Text>

                    <Text className={`${styles.contentEn}  ${styles.fontBold}`}>Personal Information   </Text>
                    <Text className={`${styles.contentEn}`} mb={10}>Personal information refers to information about the users  (hereinafter referred to as "Users") ofthe platform provided by the Company, which can identify the Users by their name, address,telephone number, email address, university name, and other information and data. Also, even if itis not identifiable with that information alone, it includes information that can identify anindividual user if it can be easily compared with other information. The definition of the termsused in this policy complies with the Personal Information Protection Law  (hereinafter referred toas "Personal Information Protection Law").</Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Purposes of Use of Personal Information</Text>
                    <Text className={`${styles.contentEn}`} mb={5}>The purpose for which the Company uses personal information is as defined below. We will notuse personal information beyond these purposes. </Text>
                    <Text className={`${styles.contentEn}`} >A. To confirm the identity and attributes of the User  </Text>
                    <Text className={`${styles.contentEn}`} >B. To provide matching services for recruitment and candidate pool formation purposes on theplatform</Text>
                    <Text className={`${styles.contentEn}`} >C. To carry out marketing measures </Text>
                    <Text className={`${styles.contentEn}`} >D. To carry out campaigns, public relations, surveys, and research  </Text>
                    <Text className={`${styles.contentEn}`} >E. To charge fees  (for payment processing)</Text>
                    <Text className={`${styles.contentEn}`} >F. To take measures such as service suspension against those who act in violation of the terms ofuse or use the service for fraudulent or improper purposes  </Text>
                    <Text className={`${styles.contentEn}`} >G. To improve and enhance our services</Text>
                    <Text className={`${styles.contentEn}`} >H. For the development and research of new services </Text>
                    <Text className={`${styles.contentEn}`} >I. To distribute email newsletters concerning job vacancies in accordance with applicable laws and regulations</Text>
                    <Text className={`${styles.contentEn}`} >J. In compliance with relevant laws, and based on explicit user consent, we may provide the information entered at the time of membership registration to companies registered with our services (hereinafter referred to as "participating companies")</Text>
                    <Text className={`${styles.contentEn}`} >K. We may disclose information to our business partner companies, provided that it has been processed in such a way that individuals cannot be identified, and only for the purpose of introducing our company and our services, consistent with all applicable legal requirements and standards</Text>
                    <Text className={`${styles.contentEn}`} mb={5}>L. To respond to inquiries from users and website visitors and for various contacts  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} > Changes to the Purpose of Use of Personal Information</Text>
                    <Text className={`${styles.contentEn}`} mb={5} >The Company will change the purpose of use of personal information within a range that has areasonable relationship with the purposes defined above or a range where rationality isrecognized.    </Text>

                    <Text className={`${styles.contentEn}`} mb={5}>When we change the purpose of use, we will inform users and website visitors about the changedpurpose through this policy. </Text>

                    <Text className={`${styles.contentEn}`} mb={5}>Please note that the above English translation is intended to ensure legal validity and accuracy. </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Data Acquisition </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>The information that the Company acquires or may acquire is as follows. If the acquired orpotentially acquired information corresponds to the "personal information" mentioned above, thispolicy will be applied. </Text>

                    <Box width="70%" margin="auto" mb={5}>
                        <Table variant="simple" size="md" border="2px" borderColor="black">
                            <Tbody className={`${styles.content}`}>
                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold} `}>・Information related to students</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}>Name / Date of birth / Address / Email address / Password / Information about the university (university name, degree, major, grade score / year of graduation, including expected year ofgraduation)</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold}`}>・Information related to student organizations</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}  >Organization name / University name / Telephone number / Location / Email address / Password /Website URL / Payment information when using paid services</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold}`}>・Information related to career centers</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}>Career Center Name/University Name/Telephone Number/Location/Email/Address/Password/Website URL/Payment information when using paid services</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontBold}`}>・Information related to companies</Td>
                                </Tr>

                                <Tr border="2px" borderColor="black">
                                    <Td border="2px" borderColor="black" className={`${styles.fontItalic} `}>Company Name/Telephone Number/Industry/Head Office Location/Email Address/Password/Website URL/Usage Plan/Payment information when using paid services</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </Box>


                    <Text className={`${styles.contentEn}`} mb={5}>In addition, we also collect information about users' use of services, status, profile pictures, andphotos. When the Company releases an application, we will also obtain information through theapplication. </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >User Personal Information Provision  </Text>
                    <Text className={`${styles.contentEn}`} mb={5} >We require the user to provide personal information to the extent necessary to achieve the above"Purpose of Use of Personal Information". If you are unable to provide information on specificmatters, please note that you may not be able to use all or part of our services </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Safety Management Measures</Text>
                    <Text className={`${styles.contentEn}`} mb={5} > We take measures for safety management to prevent leakage or loss of personal information wehandle. The following items are included in these safety management measures:</Text>

                    <Text className={`${styles.contentEn}`} >A. Appointing managers responsible for handling personal information </Text>
                    <Text className={`${styles.contentEn}`} >B. Implementing security measures to protect personal information from acts of intrusion fromoutside for unlawful purposes, such as theft of information, or unauthorized access</Text>
                    <Text className={`${styles.contentEn}`} >C. Preventing viewing by those within our organization who do not have the authority to viewpersonal information  </Text>
                    <Text className={`${styles.contentEn}`} >D. Preventing theft or loss of equipment, electronic media, and documents handling personalinformation  </Text>
                    <Text className={`${styles.contentEn}`} mb={5} >E. Implementing and supervising education on handling and protecting personal information foremployees </Text>

                    <Text className={`${styles.contentEn}  ${styles.fontBold}`} >    Third Party Provision and Sharing  </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Except for the reasonable reasons listed below, we will not provide your personal information to athird party without your consent. Even when sharing personal information with a third party, thepurpose of its use is limited to the "Purpose of Use of Personal Information".</Text>


                    <Text className={`${styles.contentEn}`} >A. When using the payment service "Stripe"  (only information related to bank accounts, creditcards, and other payments is applicable)</Text>
                    <Text className={`${styles.contentEn}`} >B. When the user himself/herself requests disclosure or provision to a third party</Text>
                    <Text className={`${styles.contentEn}`} >C. When required by law</Text>
                    <Text className={`${styles.contentEn}`} mb={5}>D. When there is a reorganization or business transfer, etc., and our company and a third partyappear</Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >    Outsourcing of Personal Information Processing  </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>We may outsource all or part of the work related to the handling of personal information. In suchcases, we take responsibility for the handling of personal information at the outsourcingdestination and ensure that the safety management of personal information is carried out. </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Request for Notification of Purpose of Use, etc. </Text>
                    <Text className={`${styles.contentEn}`} >    A. Notification of purpose of use or disclosure of personal information or third party provisionrecords</Text>
                    <Text className={`${styles.contentEn}`} mb={5} >  Users and those who intend to use our services and platforms  (hereinafter referred to as "users,etc." in this section) can request notification of the purpose of use or disclosure of personalinformation or third-party provision records within the range of legitimate purposes. However,this does not apply when it is against the law to disclose or when we deem it extremelyinappropriate.</Text>

                    <Text className={`${styles.contentEn}`} >  B. Correction, etc.</Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Users, etc. can request correction, addition, or deletion  (hereinafter referred to as "correction,etc.") of personal information. In such cases, when there is a reasonable reason for the request,we will correct the personal information.  </Text>

                    <Text className={`${styles.contentEn}`} >C. Suspension of use or third party provision</Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Users, etc. can request the suspension of use of personal information or the suspension ofprovision to a third party. When such a request is made, unless it is recognized as an abuse ofrights, we will promptly stop using the information or stop providing it to a third party.  </Text>

                    <Text className={`${styles.contentEn}`} >The above requests can be made not only by the user himself/herself but also by a voluntary agentor legal agent. *When a request is made from the user himself/herself, the person in charge ormanager of our company will verify the identity of the user, and when a request is made from anagent, we will confirm the qualifications of the agent.  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >About Cookies </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Our website may use Cookies  (information that is sent from the server to the user's browser andstored on the user's computer for the purpose of identifying the computer used by the user by thewebsite provider). Generally, cookies do not constitute personal information, but when they canidentify a specific individual in combination with personal information as mentioned in the"Personal Information" section above, we consider cookie information to be personal informationas well.  </Text>

                    <Text className={`${styles.contentEn}`} mb={5} >*By using cookies, we obtain information such as customer's device information, locationinformation, and behavior history. However, even if we use cookies, we do not obtain informationthat can identify the user personally  (e.g., name, address, telephone number, etc.) by such usealone.</Text>

                    <Text className={`${styles.contentEn}`} > The obtained information will be used for the following purposes.</Text>
                    <Text className={`${styles.contentEn}`} >A. To smoothly perform account creation and login to the member page  (such as input assistance) </Text>
                    <Text className={`${styles.contentEn}`} >B. To display content optimal for the user  </Text>
                    <Text className={`${styles.contentEn}`} >C. For statistical understanding of browsing conditions for the purpose of improving the website </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>D. For other purposes to enhance the convenience of browsing the website for users</Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >About the use of Google Analytics   </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>  We may use Google Analytics, a service provided by Google that analyzes website access andcaptures visitor behavior. Google collects users' browsing history etc. based on cookies set by usor Google, we receive and use the analysis results for understanding usage conditions andimproving our services. For details on how Google handles data in Google Analytics, please visitthe following website:</Text>

                    <Text className={`${styles.contentEn}`} mb={5} >"Use of information collected from sites or apps that use our services by Google"   </Text>

                    <Link className={`${styles.content} ${styles.linkColor}`} href="https://policies.google.com/technologies/partner-sites" isExternal>https://policies.google.com/technologies/partner-sites</Link>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} mt={10} >Handling of Data for Statistics  </Text>
                    <Text className={`${styles.contentEn}`} mb={5} >We may create statistical data that cannot identify individuals based on the personal informationprovided. For this data, we can use it without any restrictions.</Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Changes to this Policy </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>If amendments to related laws are enforced, points to be improved are found, or when it isreasonable to change this policy, we will revise this policy and disclose the changes as part of thispolicy.</Text>
                    <Text className={`${styles.contentEn}`} >Inquiries</Text>
                    <Text className={`${styles.contentEn}`} mb={5}>For inquiries about this policy or requests for notification of the purpose of use, etc., please send them tothe following e-mail address. The person in charge or the manager or other department handlingpersonal information will respond. Please note that we may charge a fee according to the PersonalInformation Protection Law when you make a request for notification of the purpose of use or disclosureof personal information or third-party provision records.   </Text>

                    <Link className={`${styles.content} ${styles.linkColor}`} href="mailto:info@jelper.co" isExternal>Mail: info@jelper.co</Link>

                    <Center>
                        <Text className={`${styles.content} ${styles.fontBold}`} mt={20} mb={5} fontSize={22} >Special Provisions  </Text>
                    </Center>

                    <Text className={`${styles.contentEn}`} mb={5}>The following provisions will additionally apply depending on the country or region to which the subjectof the personal data belongs.</Text>

                    <Text className={`${styles.contentEn}`} >[Regarding GDPR]  </Text>
                    <Text className={`${styles.contentEn}`} >In the processing of personal data of individuals residing in the EU region, we will handle the data inaccordance with the following principles set forth in Article 5 of the GDPR. </Text>
                    <Text className={`${styles.contentEn}`} >A Principle of legality, fairness, and transparency</Text>
                    <Text className={`${styles.contentEn}`} >B Principle of purpose limitation</Text>
                    <Text className={`${styles.contentEn}`} >C Principle of data minimization </Text>
                    <Text className={`${styles.contentEn}`} >D Principle of accuracy</Text>
                    <Text className={`${styles.contentEn}`} >E Principle of storage limitation</Text>
                    <Text className={`${styles.contentEn}`} mb={5} >F Principle of integrity and confidentiality </Text>

                    <Text className={`${styles.contentEn}  ${styles.fontBold}`} >Legal Requirements </Text>
                    <Text className={`${styles.contentEn}`} >We will process personal data only in the following cases:</Text>
                    <Text className={`${styles.contentEn}`} >A When the user gives consent to the processing of the data</Text>
                    <Text className={`${styles.contentEn}`} >B When it is necessary to process data in order to comply with legal obligations</Text>
                    <Text className={`${styles.contentEn}`} mb={5} >C When it is necessary to process data for other legitimate interests, except when the rights and interestsof the data subject override such legitimate interests.  </Text>

                    <Text className={`${styles.contentEn}  ${styles.fontBold}`} >Information Disclosure  </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>When we collect personal data, we will disclose the full text of this policy, including these specialprovisions, to the subject of the data.  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Retention Period of Personal Data </Text>
                    <Text className={`${styles.contentEn}`} mb={5} >The period for which we store personal data in our company will be a reasonable period of time from thepoint when the user to whom the data belongs no longer uses our platform.  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >User Rights</Text>
                    <Text className={`${styles.contentEn}`} >Users have the right to access, correct, and request deletion of their personal data from us.In addition, they also have the following rights: </Text>
                    <Text className={`${styles.contentEn}`} >A Right to restriction of processing  (the right to limit the handling of personal data without erasing it) </Text>
                    <Text className={`${styles.contentEn}`} >B Right to object  (the right to object to the processing of one's personal data for legitimate interests)</Text>
                    <Text className={`${styles.contentEn}`} >C Right to data portability  (the right to demand the handover of personal data in a readable andexportable data format to oneself or another controller) </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>D Right to oppose profiling  (the right to not be subjected to decisions based solely on automatedprocessing, including profiling, which produces legal effects concerning oneself or similarly significantlyaffects oneself)  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Recipients of Personal Data </Text>
                    <Text className={`${styles.contentEn}`} mb={5}>Those who can receive personal data are our company and those third parties to whom we objectivelydeem it necessary to provide such data.  </Text>

                    <Text className={`${styles.contentEn}`} >[Personal Information Protection Law and Personal Information Protection Standard in China] </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Personal Information Administrator</Text>
                    <Text className={`${styles.contentEn}`} mb={5} >The administrator for personal information will be an officer of our company for the time being. If theadministrator is changed, this special provision will be changed.  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Collection of Personal Information  </Text>
                    <Text className={`${styles.contentEn}`} mb={5} >When using services through the use of our platform, we collect information about the user as listed inthe table in the section "About Collected Data". The same applies to account creation. The routes ofpersonal information collection are from the user's contact with our company, our website, and theapplication when operating it. </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Retention Period of Personal Information  </Text>
                    <Text className={`${styles.contentEn}`} mb={5} >We will set a retention period for the collected personal information. Not only when we receive a deletionrequest from the user, but also when the user indicates that they do not want to use our services, orwhen it is confirmed that they have not used the services for more than a year, we will delete the relevantpersonal information.  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Third Party Provision of Personal Information  </Text>
                    <Text className={`${styles.contentEn}`} mb={5} >We will provide personal information to third parties when it is unavoidable, or when using paid services.Unavoidable circumstances include cases where Japanese law requires third party provision, and caseswhere it is necessary to avoid imminent danger to the user themselves or a third party. In these cases, wemay provide personal information to national or local public entities, courts, etc. In addition, when usingpaid services, we provide payment-related information to the payment service "Stripe".  </Text>

                    <Text className={`${styles.contentEn} ${styles.fontBold}`} >Rights Related to Personal Information</Text>
                    <Text className={`${styles.contentEn}`} mb={10} >The rights concerning the user's personal information shall be subject to the related provisions such asthe notification request for the purpose of use in this policy. </Text>
                    <Text className={`${styles.contentEn}`} mb={20}>Established in June 2023  </Text>
                </Box>
            </Center >
        </>
    )
}


export default PrivacyPolicy;