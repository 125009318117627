import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Text, Center } from "@chakra-ui/react";

// css
import styles from "../css/ComingSoon.module.css";

function ComingSoon() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/jcs');
        }, 3000);
    }, []);
    return (
        <>
            <Center>
                <Text className={styles.comingSoon}> Coming Soon...</Text>
            </Center>
        </>
    )
}


export default ComingSoon;