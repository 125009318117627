import React from 'react';
import {
  Box, Heading, Text, Center, Image, VStack, HStack, Link, Icon,
  useColorModeValue, useBreakpointValue
} from "@chakra-ui/react";
import { FaRegHandPointLeft } from 'react-icons/fa';

import logo from './logo/jelper-club-logo.png';

export const Notfound = () => {
  const bgColor = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <div>
      <Box bg={bgColor} color={textColor}>
        <Center minH={'calc(100vh - 80px)'} >
          <VStack spacing={6}>
            <HStack spacing={4}>
              <Image
                alt={"Jelperclub-logo"}
                src={logo}
                boxSize={'150px'}
              />
              {isMobile ?
                <Box>
                  <Heading size={'4xl'} mb={2}>
                    404
                  </Heading>
                  <Heading size={'xl'} mb={4}>
                    Sorry... <br /> Not Found
                  </Heading>
                </Box>
                : <VStack>
                  <Heading size={'4xl'} mb={2}>
                    404
                  </Heading>
                  <Heading size={'xl'} mb={4}>
                    Sorry... Not Found
                  </Heading>
                </VStack>
              }
            </HStack>
            {isMobile ?
              <Text fontSize="2xl" maxW={'620px'} textAlign="center">
                The page you are looking for may be temporarily inaccessible or may have been moved or deleted. Please make sure that there is no typos in URL and file name.
              </Text> :
              <Text fontSize="2xl" maxW={'620px'} textAlign="center">
                The page you are looking for may be temporarily inaccessible or may have been moved or deleted. <br /> Please make sure that there is no typos in URL and file name.
              </Text>
            }

            <Link fontSize={'xl'} href='/'><Icon as={FaRegHandPointLeft} /> Back to Top</Link>
          </VStack>
        </Center>
      </Box>
    </div>
  );
}
