import {
    Box, Center, Container, Image,
    Button, Text, Link, Heading, Divider, Stack,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
} from "@chakra-ui/react";
import axios from "axios";

// logos
import MUFG11 from "../logo/mufg.png"
import MUFGImg211 from '../logo/mufgimage1.jpg'
import MUFGImg311 from '../logo/mufgimage2.jpg'
import MUFGImg411 from '../logo/mufgimage3.jpg'
import pinpointImg111 from "../logo/pinpoint_intro1.jpg";
import pinpointImg211 from "../logo/pinpoint_intro2.jpg";
import pinpointImg311 from "../logo/pinpoint_intro3.jpg";
import pinpointImg411 from "../logo/pinpoint_intro4.jpg";
import pinpointImg511 from "../logo/pinpoint_intro5.jpg";
import pinpoint11 from '../logo/pinpoint.png'
import plandosee11 from '../logo/plandosee.png'
import plandoseeImg111 from '../logo/plandoseeimage1.png'
import plandoseeImg211 from '../logo/plandoseeimage2.jpg'
import plandoseeImg311 from '../logo/plandoseeimage3.png'
import fujifilm11 from '../logo/fujifilm.jpg'
import fujifilmImg111 from '../logo/fujifilmimage1.jpg'
import fujifilmImg211 from '../logo/fujifilmimage2.png'
import fujifilmImg311 from '../logo/fujifilmimage3.png'
import timewitch11 from '../logo/Timewitch.png'
import timewitchImg111 from '../logo/timewitchimage1.jpg'
import timewitchImg211 from '../logo/timewitchimage2.jpg'
import dxyz11 from '../logo/dxyz.jpg'
import dxyzImg111 from '../logo/dxyzimage1.jpg'
import dxyzImg211 from '../logo/dxyzimage2.jpg'
import dxyzImg311 from '../logo/dxyzimage3.jpg'
import suntech11 from '../logo/suntech.jpg'
import suntechImg111 from '../logo/suntechimage1.jpg'
import suntechImg211 from '../logo/suntechimage2.jpg'
import suntechImg311 from '../logo/suntechimage3.jpg'
import cyberagent111 from '../logo/cyberagent1.png'
import cyberagent211 from '../logo/cyberagent2.png'
import cyberagentimage111 from '../logo/cyberagentimage1.jpg'
import cyberagentimage211 from '../logo/cyberagentimage2.jpg'
import cyberagentimage311 from '../logo/cyberagentimage3.jpg'

const MUFG1 = MUFG11
const MUFG2 = MUFGImg211
const MUFG3 = MUFGImg311
const MUFG4 = MUFGImg411
const pinpointImg11 = pinpointImg111
const pinpointImg21 = pinpointImg211
const pinpointImg31 = pinpointImg311
const pinpointImg41 = pinpointImg411
const pinpointImg51 = pinpointImg511
const pinpoint1 = pinpoint11
const plandosee1 = plandosee11
const plandoseeImg11 = plandoseeImg111
const plandoseeImg21 = plandoseeImg211
const plandoseeImg31 = plandoseeImg311
const fujifilm1 = fujifilm11
const fujifilmImg11 = fujifilmImg111
const fujifilmImg21 = fujifilmImg211
const fujifilmImg31 = fujifilmImg311
const timewitch1 = timewitch11
const timewitchImg11 = timewitchImg111
const timewitchImg21 = timewitchImg211
const dxyz1 = dxyz11
const dxyzImg11 = dxyzImg111
const dxyzImg21 = dxyzImg211
const dxyzImg31 = dxyzImg311
const suntech1 = suntech11
const suntechImg11 = suntechImg111
const suntechImg21 = suntechImg211
const suntechImg31 = suntechImg311
const cyberagent11 = cyberagent111
const cyberagent21 = cyberagent211
const cyberagentimage11 = cyberagentimage111
const cyberagentimage21 = cyberagentimage211
const cyberagentimage31 = cyberagentimage311


const MUFG = "/static/media/mufg.cd240024def0664e8e53.png";
const MUFGImg2 = "/static/media/mufgimage1.4e43c79b7a10d01248bd.jpg";
const MUFGImg3 = "/static/media/mufgimage2.28b936ef0f6c4f3a1054.jpg";
const MUFGImg4 = "/static/media/mufgimage3.c1653d797286f4fe0a4d.jpg";
const pinpointImg1 = "/static/media/pinpoint_intro1.8170a6876d81b8107b0d.jpg";
const pinpointImg2 = "/static/media/pinpoint_intro2.24b41ff069084fee46d6.jpg";
const pinpointImg3 = "/static/media/pinpoint_intro3.d683a58b64fbeae906e4.jpg";
const pinpointImg4 = "/static/media/pinpoint_intro4.debfadb1239b0fa92db6.jpg";
const pinpointImg5 = "/static/media/pinpoint_intro5.8d842586465aac64e43d.jpg";
const pinpoint = "/static/media/pinpoint.70c193fcd188e477918a.png";
const plandosee = "/static/media/plandosee.231fab1695e5058a270e.png";
const plandoseeImg1 = "/static/media/plandoseeimage1.3b828badb191d5501f47.png";
const plandoseeImg2 = "/static/media/plandoseeimage2.6f1406df69ea69da3772.jpg";
const plandoseeImg3 = "/static/media/plandoseeimage3.808025655303ab5fb782.png";
const fujifilm = "/static/media/fujifilm.5174110718995526c857.jpg";
const fujifilmImg1 = "/static/media/fujifilmimage1.490fe7d0012f61bf407d.jpg";
const fujifilmImg2 = "/static/media/fujifilmimage2.00c3c224c9141a28ece1.png";
const fujifilmImg3 = "/static/media/fujifilmimage3.9ca87de484856e9c1bf0.png";
const timewitch = "/static/media/Timewitch.c8b34e83a29519cdd9e9.png";
const timewitchImg1 = "/static/media/timewitchimage1.f64e4d3dfe20ed54090c.jpg";
const timewitchImg2 = "/static/media/timewitchimage2.f60191c909c5240cd844.jpg";
const dxyz = "/static/media/dxyz.0c45f4ff532aaba05c81.jpg";
const dxyzImg1 = "/static/media/dxyzimage1.e5504916d55dd5f9d741.jpg";
const dxyzImg2 = "/static/media/dxyzimage2.937b93cb4d583c65fa67.jpg";
const dxyzImg3 = "/static/media/dxyzimage3.48c4a9182cbed68ef5c7.jpg";
const suntech = "/static/media/suntech.299257350d64c5612475.jpg";
const suntechImg1 = "/static/media/suntechimage1.0f7388e4653adf080302.jpg";
const suntechImg2 = "/static/media/suntechimage2.5b98ba5c81b6f84a7568.jpg";
const suntechImg3 = "/static/media/suntechimage3.39fd8ba161473a2ed831.jpg";
const cyberagent1 = "/static/media/cyberagent1.5811e1489b7257079144.png";
const cyberagent2 = "/static/media/cyberagent2.307cbd3902b74d7ca18f.png";
const cyberagentIMG1 = "/static/media/cyberagentimage1.1a1ce64331d0ea50e108.jpg";
const cyberagentIMG2 = "/static/media/cyberagentimage2.677fcba207d9ddbb3b05.jpg";
const cyberagentIMG3 = "/static/media/cyberagentimage3.4f2eb3dfd5779dd702fc.jpg"

export interface pluginProps {
    isOpen: boolean,
    onClose: () => void,
    title: string,
}

const sendDiscord = (text: string) => {
    const URL = 'https://discord.com/api/webhooks/1163303871272198245/zEJkqiRtYI5fZW8slI9z8dm3BW69_s9RMJIlCMstisWlj503p9prEkdgjkOmkG-GHIGX';
    const postData = {
        content: text
    }

    axios.post(URL, postData,
        {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
};

function CompanyIntro({ isOpen, onClose, title }: pluginProps) {

    // MUFG Company PR
    const MUFGCompany = () => {

        const MUFGCompanyPR = () => {
            sendDiscord('MUFGが閲覧されました．');
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'MUFGは、全世界で約270社のグループ会社、約18万人の社員が働く日本最大級、そして世界有数の総合金融グループです。商業銀行、信託銀行、証券会社のほか、クレジットカードや総合リースなど、さまざまな金融分野でトップクラスの企業で構成されている点は他の金融グループにないMUFGの強みです。\n\n'}
                        {'総合金融グループとしての革新性・柔軟性をベースに、お客さまに最高水準の総合金融サービスを提供することで、国内外の経済発展を根底から支えることがMUFGの使命です。\n\n'}
                        {'近年はアジア全域におけるトップバンクの地位を確立すべく、ASEAN地域だけで約1兆円の投資を完了。MUFGのサービスをアジア全域に拡大し、成長を続けています。今後もアジアだけでなく、全世界での経済の発展に最前線で挑戦して参ります。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'MUFG is one of the largest and most notable comprehensive financial groups in the world, and certainly the largest in Japan, employing approximately 180,000 employees across about 270 group companies worldwide. Our strength lies in the composition of top-tier companies across diverse financial sectors, including commercial banks, trust banks, securities companies, as well as credit card and comprehensive leasing services. This distinction sets MUFG apart from other financial groups.\n\n'}
                        {'MUFG\'s mission is grounded in providing the highest standard of comprehensive financial services to our clients, leveraging our innovative and flexible approach as a comprehensive financial group. Our role extends to underpinning economic development both domestically and internationally.\n\n'}
                        {'In recent years, we have completed investments of approximately one trillion yen solely in the ASEAN region, as part of our efforts to establish our position as a leading bank throughout Asia. This has allowed us to expand MUFG\'s services across the entirety of Asia and continue our growth. Going forward, MUFG will not only challenge ourselves in Asia but also strive to contribute to economic development on a global scale.'}
                    </Text>
                </div>
            )
        };

        const MUFGBusinessContent = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'企業には、「創業期」「成長期」「成熟期」といったビジネスステージがあります。MUFGでは、ベンチャー企業から大企業まで、各ビジネスステージに応じた最適なサービスを様々な業界に対して提供しています。グループ総合力を活かし、事業へのコンサルティング、金融の枠を超えたソリューション提供、アフターフォローまでを丁寧に行うことで、プロジェクト単位ではない、お客さまや社会との長期的且つ良好な関係を築くことで社会を支え続けています。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'In the business world, companies go through different stages such as the "startup phase," the "growth phase," and the "maturity phase." At MUFG, we provide optimal services tailored to these varying business stages, catering to a diverse range of industries from start-ups to large corporations. Leveraging the collective strengths of our group, we offer careful consulting for businesses and provide solutions beyond the scope of finance, extending to diligent post-service follow-ups.  Rather than confining ourselves to individual projects, we place great importance on cultivating long-term, beneficial relationships with our clients and society. In this way, we consistently play our part in sustaining societal progression.\n\n'}
                    </Text>
                </div>
            )
        };

        const MUFGPostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'■ オープンコース / Open Course \n\n'}
                        {'■ グローバルコース / Global Course\n\n'}
                    </Text>
                </div>
            )
        };

        const MUFGPositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        <Text whiteSpace="pre-line" fontWeight="bold">{'■ オープンコース： \n'}</Text>
                        {'法人業務、リテール業務、プライベート・バンキング業務、国際業務、投資銀行業務、市場業務、システム/デジタル関連業務、会計業務（日米基準)、ガバナンス関連業務、本部（含む企画・人事） 等金融業務全般\n\n'}
                        <Text whiteSpace="pre-line" fontWeight="bold">{'■ グローバルコース： \n'}</Text>
                        {'法人業務、リテール業務、プライベート・バンキング業務、投資銀行業務、市場業務、システム/デジタル関連業務、会計業務（日米基準)、ガバナンス関連業務、本部（含む企画・人事） 等金融業務全般\n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        <Text whiteSpace="pre-line" fontWeight="bold">{'■ Open Course: \n'}</Text>
                        {'Corporate Business, Retail Business, Private Banking, International Business, Investment Banking, Market Operations, Systems/Digital-related Operations, Accounting Operations (based on Japanese and U.S. standards), Governance-related Operations, and Headquarters (including Planning and Human Resources), among other comprehensive financial operations.\n\n'}
                        <Text whiteSpace="pre-line" fontWeight="bold">{'■ Global Course: \n'}</Text>
                        {'Corporate Business, Retail Business, Private Banking, Investment Banking, Market Operations, Systems/Digital-Related Operations, Accounting Operations (based on Japanese and U.S. standards), Governance-Related Operations, and Head Office Functions (including Planning and Human Resources), among other comprehensive financial operations.\n\n'}
                    </Text>
                </div>
            )
        };

        const MUFGNote = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'イベント当日は以下の時間帯で会社説明会及びQ&Aセッションを実施いたします。\n\n'}
                        {'On the days of the event, PinPOINT will conduct a company presentation and Q&A session during the following time slots.\n\n'}
                        {'・1日目 / 10/28 (Sat):\n\n'}
                        {'　04:10PM～04:40PM・05:00PM～05:30PM・05:50PM～06:20PM・06:40PM～07:10PM (日本時間/JST)\n\n'}
                        {'　08:10AM～08:40AM・09:00AM～09:30AM・09:50AM～10:20AM・10:40AM～11:10AM  (イギリス夏時間/BST)\n\n'}
                        {'・2日目 / 10/28 (Sat):\n\n'}
                        {'　07:10PM～07:50PM・08:10PM～08:50PM・09:10PM～09:50PM・10:10PM～10:50PM・\n　11:10PM～11:50PM・00:10AM～00:50AM  (米国東部夏時間/EST) \n\n'}
                        {'　04:10PM～04:50PM・05:10PM～05:50PM・06:10PM～06:50PM・07:10PM～07:50PM・\n　08:10PM～08:50PM・09:10PM～09:50PM  (米国西部夏時間/PST) \n\n'}
                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'100%'} width={'35%'} objectFit={'contain'}
                            src={MUFG} alt={"MUFG Bank, Ltd. / 株式会社三菱UFJ銀行"} pt={10} pb={10} />
                        <Box textAlign="center">
                            <Heading size="lg" mb={'2%'}>  {/* <-- this is where you set the margin-bottom */}
                                MUFG Bank, Ltd. /
                            </Heading>
                            <Heading size="lg">
                                株式会社三菱UFJ銀行
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='xl' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        Banking
                    </Text>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                企業 PR
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company PR
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <MUFGCompanyPR />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={10} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'50%'} src={MUFGImg3} alt={"MUFG"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                事業内容
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Business Content Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <MUFGBusinessContent />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'65%'} src={MUFGImg2} alt={"MUFG"} m="auto" />
                        <Text whiteSpace="pre-line" fontWeight="bold" pt={10}></Text>
                        <Image width={'65%'} src={MUFGImg4} alt={"MUFG"} m="auto" />
                    </Stack>


                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <MUFGPostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <MUFGPositionOverview />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                備考
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Note
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <MUFGNote />
                        </Box>
                    </Box>
                </ModalBody>

                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'mailto:SAIYO_TOKYO_PF@mufg.jp'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };

    const PinPOINTCompany = () => {
        sendDiscord('PinPOINTが閲覧されました．');

        const PinPOINTCompanyPR = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'PinPOINTは1999年に創業した、中国の資産運用業界で最も歴史のあるヘッジファンドの一つです。ピンポイントは過去20年にわたって、アジアを中心としてその規模を拡大してきました。現在では香港、上海、東京、ムンバイ、シンガポールに拠点を有する、数千億円規模の運用規模を持つヘッジファンドです。\n 資産運用においては複数のポートフォリオマネージャーが各自の裁量で運用を行うマルチマネージャー制度を採用しています。各運用者の個性に合わせた多種多様な投資戦略を駆使することにより、運用規模の拡大と戦略の多様化を実現しています。私たちは安定的なアルファ (市場の上下動に依らないリターン) の創出と、お預かりした資産を大幅な市場変動から守ることを最優先に考えています。これにより、いくつもの市場サイクルを越えて、ここまでの成長を実現してきました。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'xl'} mb={3}>
                        {'◆ About us\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'・Established in 1999, PinPoint has been one of the most historic hedge funds across Asia.\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'・PinPoint is managing over $5bn with around 110 professionals across 5 offices in Asia.\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'・PinPoint is devoted to generating stable returns for investors and becoming the best hedge fund platform in Asia with fundamental research and Long/Short strategy.\n\n'}
                    </Text>
                </div>
            )
        };

        const PinPOINTPostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'■ 株式アナリスト インターンシップ / Investment Analyst Intern \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'■ 株式アナリスト 本選考 / Investment Analyst \n\n'}
                    </Text>
                </div>
            )
        };

        const PinPOINTPositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" fontSize={'xl'} fontWeight={'bold'} mt={5}>
                        {'■ 株式アナリスト インターンシップ / Investment Analyst Intern \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'lg'}>
                        {'応募要件 \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'・2023/2024 年に修士、あるいは博士課程を卒業予定であること\n・東京オフィスで勤務することができ、日本語と英語でのコミュニケーションが可能であること\n・数学、統計学、金融工学、情報技術、工学、データ分析、経済学およびこれらに関連する学科を専攻していること\n・問題解決において論理的\n・数理的なアプローチを駆使できること\n・自立して問題解決にあたり、知的好奇心が旺盛で、物事の本質を深く突き詰めて考えられること\n・プレッシャーに負けず、複数のタスクを同時並行してこなせること\n・困難に進んで立ち向かい、それを克服しようとする意欲があること\n・株式マーケットでリターンをあげることに強い情熱をもって取組み、ヘッジファンド業界でキャリアを形成していきたいと考えていること\n・「何としてもヘッジファンドマネージャーになりたい」という強い意志があること \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'lg'}>
                        {'<Qualifications>  \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'◆Target Candidate(s)\n Student, Student w/Exp \n\n◆Degree\nMBA Master Bachelor JD Ph.D. Associate Exchange Program Other\n\n◆University Major Field\n STEM\n\n◆Language Skill\n・English : Fluent level\n・Japanese : Native level\n\n◆Requirements:\n• 2023-2024 graduates with a master\'s degree or above\n• For hiring located in Tokyo, Fluency in Japanese and English is required\n• With trainings (during undergrad or graduate) in Mathematics, Statistics, Financial Engineering, Electronic Information, Engineering, Data Analysis, Finance and other related majors;\n• Can use logical thinking and mathematical thinking to analyze problems;\n• Solve problems independently, eager to new knowledge, and delve into the roots;\n• Excellent multi-task ability and stress resistance;\n• Willing to take challenges and facing up to difficulties;\n• Full of passion and interest in the secondary market, especially the hedge fund industry;\n• Desire to be a hedge fund manager.\n\n'}
                    </Text>
                    <Divider border={'gray 0.1px solid;'} />
                    <Text whiteSpace="pre-line" fontSize={'xl'} fontWeight={'bold'} mt={5}>
                        {'■ 株式アナリスト 本選考 / Investment Analyst \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'lg'}>
                        {'業務概要 \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'1. CIO直属のチームに所属し、投資調査部⻑、セクターポートフォリオマネージャーや他のアナリストと協業して調査を担当。\n\n2. 業界調査:特定の1業種(情報技術、消費財、ヘルスケア、景気敏感および金融セクター)の専任アナリストとなり、重要テーマについてのファンダメンタルズ調査、および特定業種のサプライチェーン関連企業について綿密な調査を行う。業界のデータを注視し、業界動向を注意深くフォローする。\n\n3. 企業調査:バリューチェーンについての徹底した調査を通じて、担当企業についての深い知識を獲得する。十分な調査を経て、独立した視点で客観的な企業価値評価を行う。\n\n4. 投資提案:投資機会を精査し、株式価値のミスプライシングを発見することでポートフォリオマネージャー、およびCIOに投資提案を行う。\n\n5. 事前準備:ポートフォリオ構築やリスク管理など、さらなる発展に向けた準備を行う。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'lg'}>
                        {'◆Responsibilities: \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'1. Receive comprehensive training and practice on fundamental research for real-life cases, reporting to CIO directly; \n\n2. Conduct fundamental research on key themes and dig deep into the upstream and down stream supply chain of specific industries; \n\n3. Track market trend and portfolio status quo closely under the guidance of the portfolio managers; \n\n4. Pitch investment ideas to portfolio managers or CIO with reasonable valuation, modeling and downside protection; \n\n5. Prepare for further development including portfolio construction and risk management.\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'lg'}>
                        {'応募要件 \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'1. 以下の専攻における修士または博士課程を卒業していること:\n理数系学科―特に数学、物理学、統計学、会計学、金融工学、コンピューターサイエンスなど \n\n2. 日本語と英語でのコミュニケーションが可能であること \n\n3. 困難な状況や高いプレッシャーの下でも、明るく、勇気をもって行動できる性格であること \n\n4. 株式投資に対して情熱を持ち、担当業種のプロフェッショナルとして、ポートフォリオマネージャーになるという明確な意志があること \n\n5. プログラミング技術があれば、優先的に考慮 \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'lg'}>
                        {'◆Requirements: \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {' 1) We are open to Master\'s degree and above, with preference in science related majors, including Mathematics, Physics, Statistics, Actuarial, Financial Engineering, Computer Science, and etc. \n\n2) We prefer candidates speaking fluent Japanese and English. \n\n3) We prefer outgoing personalities and strong will to face direct pressure and challenges. \n\n 4) We hope the candidates with strong commitment on public market investment, who can devote him/herself to this industry with a clear goal of being portfolio manager in the future.  \n\n 5) Programming skills can be a plus.'}
                    </Text>
                </div >
            )
        };

        const PinPOINTCompetitiveAdvantage = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'1.ファンダメンタルズリサーチとポートフォリオ運用について、業界最高水準の包括したトレーニングプログラムを実施していること。人材のターンオーバーが高い他のヘッジファンドプラットフォームと異なり、本当の意味で人材の長期的育成に注力している。\n\n 2.アナリストから独立したポートフォリオマネージャーとなるための、最短かつ明確なキャリアパスがあること。仮想ポートフォリオの運用に始まり、実際の資金運用、運用規模の拡大というステップを踏むことで、他社より早いプロセスでポートフォリオマネージャーとなることが可能。\n\n 3.運用業務を支援する充実した組織体制があること。社内のサポートチームに留まらず、業界トップの投資銀行や情報ベンダー、企業に対するアクセスを保有。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontSize={'lg'}>
                        {'◆Our Offer \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'1. We provide industry-leading comprehensive training on both fundamental research, portfolio construction and risk management from Chief Investment Officer and other experienced Portfolio Managers. \n\n2. We have clear and accelerated career progression for investment analysts to become independent portfolio manager with different stages, including paper portfolio management, an independent book with real money trade, and a sizable book of portfolio management with potential team build-up. \n\n3. We share full support including all-round data and research resources from top-notch onshore and offshore brokers, including Bank of America Merrill Lynch, Goldman Sachs, J.P. Morgan, Morgan Stanley, CICC, CITIC CLSA and etc. \n\n4. We offer not only a full-time buyside job, but a rare and challenging opportunity to become an independent portfolio manager, through systematic training, comprehensive practice, continuous accumulation and ultimate evolution. We hope our colleagues’ value can be recognized by fame and fortune through the development with our platform in the long-run.\n\n'}
                    </Text>
                </div>
            )
        };

        const PinPOINTNote = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'イベント当日は以下の時間帯で会社説明会及びQ&Aセッションを実施いたします。\nなお、会社説明会及びQ&Aセッションに参加される学生は、参加前に、必ずPinPointグループのブースに設置されている企業概要資料をご一読ください。\n\n'}
                        {'On the days of the event, PinPOINT will conduct a company presentation and Q&A session during the following time slots. Students who will be attending the company presentation and Q&A session are required to read the company overview materials available at the PinPoint Group booth before participating.\n\n'}
                        {'・10/28 (Sat): 05:30PM-07:30PM (JST) / 09:30AM-11:30AM (BST) / 08:30AM-10:30AM (CEST) \n'}
                        {'・10/28 (Sat): 07:00PM-09:00PM (EST) / 04:00PM-06:30PM (PST) \n\n'}
                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'100%'} width={'35%'} objectFit={'contain'}
                            src={pinpoint} alt={"PinPOINT"} pt={10} pb={10} />
                        <Box textAlign="center">
                            <Heading size="lg" mb={'2%'}>  {/* <-- this is where you set the margin-bottom */}
                                PinPOINT Group
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='xl' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        {'a Multi-Billion Dollar Hedge Fund'}
                    </Text>
                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                当社概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company Profile
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PinPOINTCompanyPR />
                        </Box>
                    </Box>

                    <Stack direction='row' spacing={'10%'} maxW={{ base: "100%", lg: "90%" }} px={20} mt={6}>
                        <Image width={'50%'} src={pinpointImg4} alt={"PinPOINT"} />
                        <Image width={'50%'} src={pinpointImg5} alt={"PinPOINT"} />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PinPOINTPostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PinPOINTPositionOverview />
                        </Box>
                    </Box>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                競争優位性
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Competitive advantage
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PinPOINTCompetitiveAdvantage />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                備考
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Note
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PinPOINTNote />
                        </Box>
                    </Box>

                    <Stack direction='row' spacing={'7.5%'} maxW={{ base: "100%", lg: "90%" }} px={20} mt={6}>
                        <Image height={'50%'} width={'33%'} src={pinpointImg1} alt={"PinPOINT"} />
                        <Image height={'50%'} width={'33%'} src={pinpointImg2} alt={"PinPOINT"} />
                        <Image height={'10%'} width={'33%'} src={pinpointImg3} alt={"PinPOINT"} />
                    </Stack>

                </ModalBody>
                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'mailto:siupo.yip@pinpointfund.com'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };


    // Plan Do See Company PR
    const PlanDoSeeCompany = () => {
        sendDiscord('Plan Do Seeが閲覧されました．');

        const PlanDoSeeCompanyPR = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'Plan·Do·Seeは海外·国内の46ヶ所でホテル·旅館·レストラン·ウェディング·コンサルティングから街づくりまで、各地域特性を生かした様々な事業を世界中で展開し、デベロップメント、ディレクションから経営まで手掛けるMade in Japanのホテルマネジメント会社です。\n\n'}
                        {'24卒就職人気企業ランキング10 位、Great Place to Work では10年連続TOP5入り、2020年から殿堂入りを果たすなど、社外からも高い評価を頂いております。\n\n'}
                        {'「日本のおもてなしを世界中の人々へ」をMissionに掲げ、"街のシンボル"となるような独自のスタイルを持った空間でお客様をおもてなしします。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Plan·Do·See is a Made-in-Japan hotel management company that handles everything from development and direction to management. The company operates various businesses worldwide, both internationally and domestically, in 46 locations.Its diverse portfolio ranges from hotels, ryokans(Japanese-style hotel), and restaurants to wedding services, consulting, and community development, all while leveraging local characteristics.  \n\n'}
                        {'The company has consistently received high external evaluations, ranking 10th in the popularity ranking for employers for the class of 2024, and securing a place in the top 5 of “the Great Place to Work” for 10 consecutive years. \n\n'}
                        {'As of 2020, it has also been inducted into the Hall of Fame. With the mission of "Bringing Japanese Hospitality to People Around the World," Plan·Do·See entertains its guests in unique spaces that serve as "Symbols of the Community.'}
                    </Text>
                </div>
            )
        };

        const PlanDoSeeBusinessContent = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'各店舗はそれぞれ独自のコンセプト・デザインを持ち、ひとつとして同じものはありません。\n\n'}
                        {'名建築のリノベーションであり、長くその土地の方々に愛されてきた場所に多くの店舗を展開して参りました。バンケット運営では新郎新婦のスタイルが感じられるウェディングや法人向け宴会のプロデュースを行っています。レストランやカフェ・バーでは、ホスピタリティ溢れるサービスと様々なこだわりのお料理でお客様をお迎えします。各店舗でホテルの支配人、ウェイター、ウェディングプランナー、新店舗の開発担当者など、多様な仕事があり、社員一同お客様の喜びを想定して仕事に取り組んでおります。\n\n'}
                        {'こうしたお客様ファーストを追求する姿勢を社員に培うことができるよう、海外研修、宿泊費補助等、スキルとセンスを磨ける制度も整えております。また、社内公募制度に加え、育休・時短・ベビーシッター制度など、社員一人一人の働きやすさと働き甲斐を追求しており、若手から声を上げて仕事を任せてもらえる裁量権と働きやすさを両立できる環境が整っております。\n\n'}
                        {'今後も日本及び世界中に事業展開を続け、街の価値を高め、関わる人々にとって心地の良い空間づくりと進化を継続して参ります。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Each of our locations boasts its own unique concept and design; no two are the same. Many of our establishments are renovations of iconic buildings that have long been cherished by local communities. In banquet operations, we produce weddings that reflect the style of the bride and groom, as well as corporate events. In our restaurants, cafes, and bars, we welcome guests with hospitable service and a variety of carefully crafted dishes. Roles within each location are diverse, ranging from hotel managers and waitstaff to wedding planners and new location development representatives. All employees are committed to anticipating and fulfilling the joy of our guests.\n\n'}
                        {'To cultivate this customer-first mentality among our staff, we offer overseas training programs, lodging subsidies, and other systems to hone their skills and sensibilities. In addition, we have established an internal recruitment system as well as policies for parental leave, reduced work hours, and babysitter services to ensure that each employee finds both satisfaction and ease in their work environment. This allows even junior members to take on responsibilities and flourish in a supportive setting.\n\n'}
                        {'Going forward, we will continue to expand our operations in Japan and around the world, enhancing the value of communities and consistently evolving to create comfortable spaces for everyone involved.\n\n'}
                    </Text>
                </div>
            )
        };

        const PlanDoSeePostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'■ 総合職 / Generalist Position \n\n'}
                        {'■ グローバルリーダー職 / Global Leader Position\n\n'}
                    </Text>
                </div>
            )
        };

        const PlanDoSeePositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'総合職 / Generalist Position: \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'■ ホテル 運営・プロデュース / Hotel Management & Production\n\n'}
                        {'■ 旅館 運営・プロデュース / Ryokan Management & Production\n\n'}
                        {'■ レストラン&バー 企画・運営 / Restaurant & Bar Planning & Management\n\n'}
                        {'■ ウェディング 企画・プロデュース / Wedding Planning & Production\n\n'}
                        {'■ 事業コンサルティング / Business Consulting\n\n'}
                        {'■ 人材事業 / Human Resources Business\n\n'}
                        {'■ 投資ファンド / Investment Fund\n\n'}
                        {'■ 制服事業 / Uniform Business\n\n'}
                        {'■ EC事業 / E-Commerce Business\n\n'}
                        {'■ IT事業 / IT Business\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'グローバルリーダー職 / Global Leader Position: \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'約5年日本で調理業務（主に和食）　その後海外へ単身勤務\n\n'}
                        {'海外店舗運営・マネージメント\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'First, gains approximately 5 years of experience in culinary work in Japan, primarily specializing in Japanese cuisine. Subsequently, the role transitions to solo employment abroad\n\n'}
                        {'Store operations and management\n\n'}
                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'105%'} width={'40%'} objectFit={'contain'}
                            src={plandosee} alt={"Plan Do See"} pt={10} pb={10} />
                        <Box textAlign="center">
                            <Heading size="xl" mb={'2%'}>  {/* <-- this is where you set the margin-bottom */}
                                Plan Do See Inc.
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='lg' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        Hotel/Restaurant/Wedding/Consulting
                    </Text>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                企業 PR
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company PR
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PlanDoSeeCompanyPR />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'65%'} src={plandoseeImg1} alt={"Plan Do See"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                事業内容
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Business Content Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PlanDoSeeBusinessContent />
                        </Box>
                    </Box>

                    <Stack direction='row' spacing={'10%'} maxW={{ base: "100%", lg: "90%" }} px={20} mt={6}>
                        <Image width={'50%'} src={plandoseeImg2} alt={"Plan Do See"} />
                        <Image width={'50%'} src={plandoseeImg3} alt={"Plan Do See"} />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PlanDoSeePostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <PlanDoSeePositionOverview />
                        </Box>
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'mailto:recruit@plandosee.co.jp'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };


    // Fujifilm Company PR
    const FujifilmCompany = () => {
        sendDiscord('Fujifilmが閲覧されました．');
        const FujifilmCompanyPR = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'富士フイルムは、デジタル化という社会の変化の中で、コア技術を活かし新たな領域に挑戦を続けることで、現在では既存の業界の垣根を超えて、14の事業を展開し、海外売上高比率が6.5割を占めるグローバル企業に成長してきました。\n\n'}
                        {'オープン・フェア・クリアの精神のもと、社会をより良い場所に変えていくために、これからも社会課題の解決に貢献し新たな価値を生み出していきます。世界中で流行するCOVID-19に対し、ワクチン原薬の製造受託、PCR検査試薬、回診用X線画像診断装置等、グループの総力を挙げて、一刻も早い感染終息に向け取り組みました。\n\n'}
                        {'日々の仕事に向き合う中で、これからの社会で必要とされる課題の本質を見抜く力、変化を恐れず挑戦し続ける力、主体性をもって働く事ができる力、謙虚に学び続ける力等を鍛えられると自負しています。そして、こうした会社の風土や仕事を通じて成長できるフィールド我々の魅力の１つです。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Fujifilm has evolved into a global enterprise that operates in 14 diverse sectors, breaking down the barriers of traditional industries. The company holds an overseas sales ratio of over 65%. \n\n'}
                        {'Guided by a spirit of openness, fairness, and clarity, Fujifilm continues to contribute to solving societal challenges and creating new value to make the world a better place. In response to the global COVID-19 pandemic, we have marshaled our collective strengths to engage in the contract manufacturing of vaccine ingredients, production of PCR testing reagents, and the development of X-ray diagnostic devices for medical rounds, among other initiatives, to expedite the end of the pandemic as swiftly as possible.\n\n'}
                        {'In our day-to-day operations, we pride ourselves on cultivating abilities such as the capacity to discern the essential challenges required in future society, the resilience to keep taking on challenges without fear of change, the ability to work proactively, and the humility to continue learning. These aspects of our corporate culture and work environment serve as one of our unique attractions.'}
                    </Text>
                </div>
            )
        };

        const FujifilmBusinessContent = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'富士フイルムは、NEVER STOPの精神で、変化を作り出す企業として挑戦を続けています。\n\n'}
                        {'成長領域である「ヘルスケア」領域では、「予防」「診断」「治療」をカバーする国内唯一のトータルヘルスケアカンパニーとして、アンメットメディカルニーズへの対応や疾病の早期発見、画期的なワクチンや医薬品の開発・製造支援等を通じて、世界中の医療問題の解決に取り組んでいます。\n\n'}
                        {'「マテリアルズ」領域では、AI／IOT時代の生活を支える半導体プロセス材料、センサー、次世代ディスプレイ等に用いる高機能材料等を提供し、資源循環の促進や気候変動への対応といった環境課題に取り組んでいます。\n\n'}
                        {'「イメージング」領域では、大切な思い出を写真というカタチに残し共有できる、チェキや写ルンです等の製品・サービスを提供し、人生を豊かにする写真の価値を発信しています。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Embodying the spirit of "NEVER STOP," Fujifilm continues to be a company that drives change. \n\n'}
                        {'In the growing sector of "Healthcare," we are only total healthcare company covering "prevention," "diagnosis," and "treatment" in Japan. We are committed to addressing unmet medical needs, early detection of diseases, and supporting the development and manufacturing of groundbreaking vaccines and pharmaceuticals to resolve global healthcare issues. \n\n'}
                        {'In the "Materials" sector, we offer semiconductor process materials, sensors, and advanced materials for next generation displays that support life in the AI/IoT era, while also addressing environmental challenges such as resource recycling and climate change. \n\n'}
                        {'In the "Imaging" sector, we provide products and services like Instax and QuickSnap, enabling people to capture and share precious memories through photography, thereby enriching lives by promoting the value of photographs. \n\n'}
                    </Text>
                </div>
            )
        };

        const FujifilmPostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'■ 事務系総合職 / Regular Position \n\n'}
                        {'■ 技術系総合職 / Technical Regular Position\n\n'}
                    </Text>
                </div>
            )
        };

        const FujifilmPositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'事務系総合職 / Regular Position: \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'■ 営業、企画 / Sales, Planning\n\n'}
                        {'■ マーケティング、本社スタッフ、工場 / Marketing, Corporate Staff, Factory\n\n'}
                        {'■ 研究所スタッフ / Research Lab Staff\n\n'}
                        {'　 etc.\n\n'}

                    </Text>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'技術系総合職 / Technical Regular Position: \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'■ 材料開発 / Material Development\n\n'}
                        {'■ 機器開発 / Equipment Development\n\n'}
                        {'■ ソフトウエア開発 / Software Development\n\n'}
                        {'■ 生産技術・製造技術 / Production Technology & Manufacturing Technology\n\n'}
                        {'　 etc.\n\n'}
                    </Text>
                </div>
            )
        };

        const FujifilmNote = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'イベント当日は、以下のスケジュールで会社説明会及びQ&Aセッションを実施いたします。\n\n'}
                        {'On the day of the event, company briefings and Q&A sessions will be conducted according to the following schedule.\n\n\n'}
                        {'・1日目 / 10/28 (Sat):\n\n'}
                        {'　事務系総合職に関する説明会 / Briefing About Regular Position:\n '}
                        {'　04:00PM～・04:30PM～・05:00PM～・05:30PM～ (日本時間/JST)\n'}
                        {'　08:00AM～・08:30AM～・09:00AM～・09:30AM～ (イギリス夏時間/BST)\n\n'}
                        {'　技術系総合職（バイオ・化学系）に関する説明会\n　/ Briefing About Technical Regular Position (Biology・Chemical related):\n'}
                        {'　07:00PM～08:00PM (日本時間/JST)\n'}
                        {'　11:00AM～00:00PM (イギリス夏時間/BST)\n\n'}
                        {'　技術系総合職（IT・機電系）に関する説明会\n　/ Briefing About Technical Regular Position (IT・Mechatronics related):\n '}
                        {'　08:00PM～09:00PM (日本時間/JST)\n'}
                        {'　00:00PM～01:00PM (イギリス夏時間/BST)\n\n'}
                        {'　事務・技術系質問会 / Q&A Session (Regular and Technical Position):\n '}
                        {'　09:00PM～10:00PM (日本時間/JST)\n'}
                        {'　01:00PM～02:00PM (イギリス夏時間/BST)\n\n\n'}

                        {'・2日目 / 10/28 (Sat):\n\n'}
                        {'　事務系総合職に関する説明会 / Briefing About Regular Position:\n '}
                        {'　07:00PM～・07:30PM～・08:00PM～・08:30PM～ (米国東部夏時間/EST)\n'}
                        {'　04:00PM～・04:30PM～・05:00PM～・05:30PM～ (米国西部夏時間/PST)\n\n'}
                        {'　技術系総合職（バイオ・化学系）に関する説明会\n　/ Briefing About Technical Regular Position (Biology・Chemical related):\n'}
                        {'　10:00PM～11:00PM (米国東部夏時間/EST)\n'}
                        {'　07:00PM～08:00PM (米国西部夏時間/PST)\n\n'}
                        {'　技術系総合職（IT・機電系）に関する説明会\n　/ Briefing About Technical Regular Position (IT・Mechatronics related):\n '}
                        {'　11:00PM～00:00AM (米国東部夏時間/EST)\n'}
                        {'　08:00PM～09:00PM (米国西部夏時間/PST)\n\n'}
                        {'　事務・技術系質問会 / Q&A Session (Regular and Technical Position):\n '}
                        {'　00:00AM～01:00AM (米国東部夏時間/EST)\n'}
                        {'　09:00PM～10:00PM (米国西部夏時間/PST)\n\n'}
                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'100%'} width={'30%'} objectFit={'contain'}
                            src={fujifilm} alt={"fujifilm"} pt={10} pb={10} />
                        <Box textAlign="center">
                            <Heading size="lg" mb={'2%'}>  {/* <-- this is where you set the margin-bottom */}
                                FUJIFILM Corporation /
                            </Heading>
                            <Heading size="lg">
                                富士フイルム株式会社
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='xl' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        Healthcare/Materials/Imaging
                    </Text>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                企業 PR
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company PR
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <FujifilmCompanyPR />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'80%'} src={fujifilmImg3} alt={"Fujifilm"} m="auto" />
                        <Text whiteSpace="pre-line" fontWeight="bold" pt={10}></Text>
                        <Image width={'80%'} src={fujifilmImg2} alt={"Fujifilm"} m="auto" />
                    </Stack>


                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                事業内容
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Business Content Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <FujifilmBusinessContent />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'65%'} src={fujifilmImg1} alt={"Fujifilm"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <FujifilmPostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <FujifilmPositionOverview />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                備考
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Note
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <FujifilmNote />
                        </Box>
                    </Box>

                </ModalBody>

                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'mailto:jimukeisaiyo@fujifilm.com'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };


    // CyberAgent Company PR
    const CyberAgentCompany = () => {
        sendDiscord('CyberAgentが閲覧されました．');

        const CyberAgentCompanyPR = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'サイバーエージェントは1998年の創業以来「21世紀を代表する会社を創る」をビジョンに掲げ、新しい未来のテレビ「ABEMA」などのメディア事業、国内トップシェアを誇るインターネット広告事業、ゲーム事業、投資育成事業の4つを主軸に事業を展開しています。\n'}
                        {'日本の東京・渋谷区に拠点を置き、インターネット産業の変化に合わせ新規事業を生み出しながら事業拡大を続けています。現在、連結子会社約100社および関連会社10社によって構成されています。\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'CyberAgent, since its founding in 1998, has been operating with the vision of "Creating a Company that Represents the 21st Century." With its headquarters in Shibuya, Tokyo, the company continuously expands its business by creating new ventures in line with changes in the internet industry. Currently, it is composed of approximately 100 consolidated subsidiaries and 10 affiliated companies.\n\n'}
                    </Text>
                </div>
            )
        };

        const CyberAgentBusinessContent = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・メディア事業\n'} </Text>
                        {'2016年4月に開局した新しい未来のテレビ「ABEMA」や、国内最大規模のブログサービス「Ameba」やマッチングアプリ「タップル」、通販化粧品の「N organic」など様々なサービスを提供しています。\n\n'}
                        <Text fontWeight={'bold'}>{'・インターネット広告事業\n'}</Text>
                        {'創業以来、インターネット広告事業を展開しており、広告効果最大化を強みに国内トップクラスの規模を誇ります。現在は、広告販売にとどまらず、AIを活用したテクノロジーや3DCG等の最先端技術を駆使したクリエイティブ制作、新たにDX事業にも参入、各業界の大手企業との協業を拡大しDX推進に取り組んでいます。\n'}
                        {'また、研究開発組織「AI Lab」を2016年に設立。高度なAI研究技術を持ち実用化に積極的な研究室と産学連携し、数多くの研究論文を発表しています。AI最高峰の国際学会「NeurIPS」「AAAI」「ICML」「CVPR」 をはじめ、世界のトップカンファレンスで数多くの論文が採択されています。その研究・開発体制と実績は、「2022年のAI研究をリードするグローバル企業トップ100※1」で日本国内企業4位（世界49位）に選ばれ高く評価されています。\n\n'}
                        <Text fontWeight={'bold'}>{'・ゲーム事業\n'}</Text>
                        {'主力タイトル8本を含む約50本のスマートフォン向けゲームを提供しています。人気アニメのフォマートフォンゲームほか、「バンドリ！ ガールズバンドパーティ！」など人気タイトルのアニメ化など、ゲームの世界観を様々なかたちで提供しています。\n\n'}
                        {'※1 AI Research Ranking 2022\n\n\n\n'}

                    </Text>
                    <Text whiteSpace="pre-line">
                        <Text fontWeight={'bold'}>{'・Media Business\n'}</Text>
                        {'Since its launch in April 2016, the "television of the future" known as ABEMA, along with the largest blog service in Japan "Ameba," the matching app "Tapple," and the cosmetic e-commerce "N organic," offer a range of services.\n\n'}
                        <Text fontWeight={'bold'}>{'・Internet Advertising Business\n'}</Text>
                        {'Since its inception, the company has been engaged in internet advertising, boasting top-tier scale domestically. Beyond just ad sales, the company leverages cutting-edge technology such as AI and 3DCG for creative production. It has also entered the DX (Digital Transformation) business, expanding collaborations with leading companies across industries. Additionally, the AI Lab was established in 2016, actively applying advanced AI research technology and publishing numerous research papers. The company has been highly rated and ranked 4th among Japanese companies (49th globally) in the "Top 100 Global Companies Leading AI Research in 2022."\n\n'}
                        <Text fontWeight={'bold'}>{'・Game Business\n'}</Text>
                        {'The company provides about 50 smartphone games, including 8 flagship titles. Popular anime-based games and titles like "BanG Dream! Girls Band Party!" are offered in various formats.\n\n'}
                        {'※1 AI Research Ranking 2022\n'}
                    </Text>
                </div>
            )
        };

        const CyberAgentPostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'■ ネイティブエンジニア（iOS/Android/Flutter） / Native Engineer (iOS/Android/Flutter) \n\n'}
                        {'■ Webフロントエンドエンジニア / Web Frontend Engineer\n\n'}
                        {'■ ゲームクライアントエンジニア / Game Client Engineer\n\n'}
                        {'■ テクニカルアーティスト・XRエンジニア / Technical Artist・XR Engineer\n\n'}
                        {'■ バックエンドエンジニア / Backend Engineer\n\n'}
                        {'■ インフラエンジニア（SRE） / Infrastructure Engineer (SRE)\n\n'}
                        {'■ インフラエンジニア（クラウドプラットフォーム） / Infrastructure Engineer (Cloud Platform)\n\n'}
                        {'■ インフラエンジニア（オフィス・スタジオ）・セキュリティエンジニア・コーポレートエンジニア / Infrastructure Engineer (Office & Studio)・Security Engineer・Corporate Engineer\n\n'}
                        {'■ 機械学習エンジニア / Machine Learning Engineer\n\n'}
                        {'■ データサイエンティスト / Data Scientist\n\n'}
                        {'■ 大規模データ処理基盤エンジニア / Large-Scale Data Processing Platform Engineer\n\n'}
                        {'■ ビジネスアナリスト / Business Analyst\n\n'}
                    </Text>
                </div>
            )
        };

        const CyberAgentPositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" >
                        {'※一部職種のみ抜粋（その他職種の職務内容については'}<Link fontWeight={'bold'} color={'green.600'} href='https://www.cyberagent.co.jp/careers/special/students/tech/#recroute_information'>こちら</Link>{'）\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・バックエンドエンジニア / Backend Engineer\n'} </Text>
                        {'日本最大級のユーザ数、トラフィック量を支えるためのプロダクト設計・開発、多種多様な言語での開発やシステム構築など\n\n'}
                        <Text fontWeight={'bold'}>{'・機械学習エンジニア\n'}</Text>
                        {'サイバーエージェントが抱える様々な事業課題に対し、各事業が保有する大規模データや機械学習技術を用いたソリューションの提案や実行など\n\n'}
                        <Text fontWeight={'bold'}>{'・データサイエンティスト\n'}</Text>
                        {'ビジネス課題に対する仮説立てや指標設計から、調査・検証・アルゴリズムの改善提案およびその開発・プロダクト実装・A/Bテスト等による効果検証など\n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" >
                        {'*Partial job listings only (For details on other positions, please refer to'}<Link fontWeight={'bold'} color={'green.600'} href='https://www.cyberagent.co.jp/careers/special/students/tech/#recroute_information'> here</Link>{')\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        <Text fontWeight={'bold'}>{'・Backend Engineer\n'}</Text>
                        {'Responsibilities include product design and development to support the largest user base and traffic in Japan, as well as system construction using various languages.\n\n'}
                        <Text fontWeight={'bold'}>{'・Machine Learning Engineer\n'}</Text>
                        {'Address various business challenges at CyberAgent by proposing and executing solutions using large-scale data and machine learning technologies.\n\n'}
                        <Text fontWeight={'bold'}>{'・Data Scientist\n'}</Text>
                        {'Involved in hypothesis generation, metric design for business challenges, investigation, validation, improvement proposal through algorithms, product implementation, and effectiveness verification through A/B testing, among other tasks.\n\n'}
                    </Text>
                </div>
            )
        };

        const CyberAgentNote = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'イベント当日は、1時間間隔で会社説明会及びQ&Aセッションを実施いたします。\n\n'}
                        {'On the day of the event, we will conduct company briefings and Q&A sessions at one-hour intervals.\n\n\n'}

                        {'・1日目 / 10/28 (Sat):\n'}
                        {'　04:30PM～・05:30PM～・06:30PM～・07:30PM～・08:30PM～  (日本時間/JST)\n'}
                        {'　08:30AM～・09:30AM～・10:30AM～・11:30AM～・00:30PM～  (イギリス夏時間/BST)\n\n'}

                        {'・2日目 / 10/28 (Sat):\n'}
                        {'　07:30PM～・08:30PM～・09:30PM～・10:30PM～・11:30PM～  (米国東部夏時間/EST) \n'}
                        {'　04:30PM～・05:30PM～・06:30PM～・07:30PM～・08:30PM～  (米国西部夏時間/PST) \n\n'}

                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'80%'} width={'50%'} objectFit={'contain'}
                            src={cyberagent1} alt={"cyberagent1"} pt={10} pb={10} />
                        <Box textAlign="center">
                            <Heading size="lg" mb={'2%'}>  {/* <-- this is where you set the margin-bottom */}
                                CyberAgent, Inc. /
                            </Heading>
                            <Heading size="lg">
                                株式会社サイバーエージェント
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='xl' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        Media/Internet Advertising/Game
                    </Text>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                企業 PR
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company PR
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <CyberAgentCompanyPR />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'65%'} src={cyberagentIMG2} alt={"Cyberagent"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                事業内容
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Business Content Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <CyberAgentBusinessContent />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'80%'} src={cyberagentIMG1} alt={"Cyberagent"} m="auto" />
                        <Text whiteSpace="pre-line" fontWeight="bold" pt={10}></Text>
                        <Image width={'80%'} src={cyberagentIMG3} alt={"Cyberagent"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <CyberAgentPostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <CyberAgentPositionOverview />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                備考
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Note
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <CyberAgentNote />
                        </Box>
                    </Box>

                </ModalBody>

                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'https://www.cyberagent.co.jp/form/id=1'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };


    // Timewitch Company PR
    const TimewitchCompany = () => {
        sendDiscord('Timewitchが閲覧されました．');

        const TimewitchCompanyPR = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'株式会社Timewitchは、2021年創業のベンチャー企業です。\n\n'}
                        {'「時差を活用し、寝ている間に仕事を動かす」をコンセプトに、日本の深夜時間帯に活動する海外の日本人スタッフと連携して翌朝に納品するというパワーポイント資料作成サービスを提供。創業からわずか2年半で500を超える企業にその価値を認められました。 この成果の背景には、BCGで経営コンサルティングに従事していたCEO三浦の先見の明と、次々とアイデアを形にする姿勢にあります。また、世界各国にいるコアメンバーは少数でありながら、場所や時間にとらわれず自由に働けるという利点を活かしています。 \n\n'}
                        {'独自開発した社内システムを駆使して、今年7月に24時間体制へとシフトし、8月からはリサーチ代行サービスも開始。最先端のAI技術を導入した新サービスの開発にも注力しています。これらのプロジェクトには学生インターンも参画し、多大な裁量権のもとで活躍しています。 \n\n'}
                        {'今後も、企業の残業をなくし、新しい働き方を創造していきます。 \n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Timewitch is a venture firm founded in 2021. \n\n'}
                        {'With the concept of "Leveraging time zones to get work done while you sleep," we offer a PowerPoint presentation creation service by coordinating with overseas Japanese staff who work during late-night hours in JST to deliver by the next morning. In just two and a half years since our founding, over 500 companies have recognized our value. Behind this success is the foresight of our CEO Miura, who previously worked in management consulting at BCG, and our attitude of continuously transforming ideas into reality. Although our core team members are spread out globally, we harness the advantage of being able to work freely, unrestricted by location or time. In July of this year, we transitioned to a 24-hour operation using our proprietary internal system and started a research proxy service in August. We are also focusing on the development of new services incorporating cutting-edge AI technology.  \n\n'}
                        {'Students participating in internships are actively involved in these projects, working with significant discretion. Moving forward, we aim to eliminate corporate overtime and create new ways of working.\n\n'}
                    </Text>
                </div>
            )
        };

        const TimewitchBusinessContent = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・'}<Link href="https://ai-alice.com/" color={'green.500'}>Alice</Link>{'：資料作成AI \n'} </Text>
                        {'AI分野という新しい市場に挑戦し、誕生したのが資料作成AIのAliceです。 \n'}
                        {'専用サイトにシンプルな文章を入力するだけで、10分以内にアイコンまで反映した洗練されたパワーポイント資料がメールで届きます。資料作成のプロフェッショナルとしてのノウハウをベースに、600種類を超える多彩なビジネステンプレートを展開しています。 \n'}
                        {'このサービスのローンチは、全て内製で、学生インターンの熱意と活躍によって実現しました。\n'}
                        {'プロダクト自体はまだ発展途上で、今後はテンプレートラインナップの拡大はもちろん、画像サジェストなどの機能拡充を計画しております。\n'}
                        {'この新しい取り組みはNewsPicksでも注目され、弊社が最も力を注いでいるプロジェクトの一つです。 \n\n'}
                        <Text fontWeight={'bold'}>{'・Timewitch：24時間以内に届くスピード特化型のパワーポイント資料作成代行サービス  \n'}</Text>
                        {'弊社が創業時より提供している資料作成代行サービスです。海外在住日本人との連携により日本の深夜時間帯の稼働を実現させました。\n'}
                        {'ベンチャーの規模ながら、内製で独自のシステムを構築し、24時間対応でパワーポイント資料をお届けしています。また、充実した研修体制によりコンサルタントレベルの資料作成を可能にし、その高いクオリティとスピードが日本の企業界におけるBtoBの領域で高い付加価値として評価されています。\n'}
                        {'今後は弊社最大の強みである海外在住日本人とのパートナーシップをさらに活かして、より幅広いBPOサービスを展開していきます。海外と日本をつなぐ、他には真似のできない独自性が、顧客の多様なニーズに応える力となっています。 \n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        <Text fontWeight={'bold'}>{'・'}<Link href="https://ai-alice.com/" color={'green.500'}>Alice</Link>{'：Slide Generation AI \n'} </Text>
                        {'Taking on the challenge of the emerging AI market, we launched Alice, an AI that creates PowerPoint presentations. Just input a simple text into the dedicated site, and a sophisticated PowerPoint presentation with icons included will be delivered to your email within 10 minutes. Leveraging our expertise as professionals in document creation, we offer over 600 diverse business templates. This service was entirely developed in-house, fueled by the passion and contributions of our student interns. The product is still evolving, and we plan to expand our template line-up and introduce new features like image suggestions. This initiative has garnered attention on NewsPicks and is one of our primary focus areas.\n\n'}
                    </Text>
                    <Text fontWeight={'bold'}>{'・Timewitch: A PowerPoint presentation creation service specialized in speedy delivery within 24 hours.  \n'}</Text>
                    {'Since our inception, we have offered this PowerPoint creation service. We make it possible to operate during nighttime hours in JST by collaborating with Japanese residents overseas. Despite being a small-scale venture, we have built our system in-house, allowing us to deliver PowerPoint presentations around the clock. Our robust training system enables us to produce consultant-level documents, and the high quality and speed have been highly valued in B2B sector in Japan. We plan to further leverage our partnership with overseas Japanese residents to offer a wider range of BPO services. Our unique ability to bridge Japan and overseas locations serves as a strength to meet diverse customer needs.\n\n'}
                </div>
            )
        };

        const TimewitchPostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'■ フロントエンジニア（インターン） / Front-end Engineer (Intern) \n\n'}
                        {'■ Webデザイナー / Web Designer (Intern)\n\n'}
                        {'■ AIエンジニア / AI Engineer\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'*以上の職種いずれも日本語要件無し / None of the above have Japanese language requirements.\n\n'}
                    </Text>
                </div>
            )
        };

        const TimewitchPositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・フロントエンジニア（インターン）\n'} </Text>
                        {'NextJs or React を用いた、社内システムのWebフロントの改善をお任せしたいです。\n'}
                        {'開発後数か月のシステムでアップデートを頻繁に行うため、リードエンジニアと相談しながら業務を進めていただきます。\n'}
                        {'Web開発に興味があり、TypescriptやReact or NextJsなどを用いたフロントエンドの開発経験(個人のポートフォリオを含む)がある方を探しています。TailwindCss、ExpressJs、MySql、Azure ADやAzure Web appサービスの知識も歓迎します。 \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・WEBデザイナー（インターン） \n'} </Text>
                        {'弊社の新サービスである、スライド作成AI'}<Link href="https://ai-alice.com/" color={'green.500'}>「Alice」</Link> {'のWebフロントのデザインをお任せしたいです。\n'}
                        {'マーケティング方針に合わせてデザインを変更することもあり、リードエンジニアと相談しながら業務を進めていただきます。\n'}
                        {'Webデザインの知識があり、ユーザーの使い心地も踏まえた提案を考えられる方を探しています。\n'}
                        {'開発には英語のノーコードツールを使用しているため、英語での情報収集を積極的に行える方を歓迎します。\n\n'}</Text>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・AIエンジニア（インターン） \n'} </Text>
                        {'弊社の新サービスである、スライド作成AI'}<Link href="https://ai-alice.com/" color={'green.500'}>「Alice」</Link> {'で、将来的に実装する新機能の開発をお任せしたいです。\n'}
                        {'要件定義に沿って機能開発を進めるため、リードエンジニアと相談しながら業務を進めていただきます。\n'}
                        {'Pythonを用いた機械学習、特に画像認識の分野における知識、開発経験がある方を探しています。 \n\n\n'}
                    </Text>

                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・Front-end Engineer (Intern)\n'} </Text>
                        {'You will be responsible for improving our in-house web front-end using NextJs or React. Frequent updates will be required for our newly-developed system, and you will work in consultation with our lead engineer. We are looking for individuals with interest in web development and experience in front-end development using TypeScript, React, or NextJs (including personal portfolios). Knowledge of TailwindCss, ExpressJs, MySql, Azure AD, and Azure Web app services is also welcome. \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・Web Designer (Intern) \n'} </Text>
                        {'You will be responsible for designing the web front-end of our new service, Slide Generation AI '}<Link href="https://ai-alice.com/" color={'green.500'}>"Alice"</Link> {'. You will work in consultation with our lead engineer to update designs based on marketing strategies. We are looking for someone with web design expertise who can also consider user experience in their design proposals. Familiarity with gathering information in English is preferred as we use English no-code tools for development.\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" >
                        <Text fontWeight={'bold'}>{'・AI Engineer (Intern) \n'} </Text>
                        {'You will be responsible for developing new features to be implemented in our new service, Slide Generation AI '}<Link href="https://ai-alice.com/" color={'green.500'}>"Alice"</Link> {'. You will work in consultation with our lead engineer to advance tasks according to the requirements. We are looking for individuals with experience and knowledge in machine learning using Python, particularly in the field of image recognition.\n\n'}
                    </Text>
                </div>
            )
        };

        const TimewitchNote = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'イベント当日は、以下の時間で会社説明会及びQ&Aセッションを実施いたします。\n\n'}
                        {'On the day of the event, we will conduct company briefings and Q&A sessions according to the following schedule.\n\n\n'}
                        {'・1日目 / 10/28 (Sat):\n'}
                        {'　05:00PM～・07:00PM～・09:00PM～  (日本時間/JST)\n'}
                        {'　09:00AM～・11:00AM～・01:00PM～  (イギリス夏時間/BST)\n\n'}

                        {'・2日目 / 10/28 (Sat):\n'}
                        {'　08:00PM～・10:00PM～・00:00AM～  (米国東部夏時間/EST) \n'}
                        {'　05:00PM～・07:00PM～・09:00PM～  (米国西部夏時間/PST) \n\n'}
                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'60%'} width={'25%'} objectFit={'contain'}
                            src={timewitch} alt={"Timewitch"} pt={10} pb={10} />
                        <Box textAlign="center">
                            <Heading size="lg" mb={'2%'}>  {/* <-- this is where you set the margin-bottom */}
                                Timewitch Inc. /
                            </Heading>
                            <Heading size="lg">
                                株式会社Timewitch
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='xl' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        Graphic design
                    </Text>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                企業 PR
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company PR
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <TimewitchCompanyPR />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'65%'} src={timewitchImg2} alt={"Timewitch"} m="auto" />
                    </Stack>


                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                事業内容
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Business Content Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <TimewitchBusinessContent />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={10} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'70%'} src={timewitchImg1} alt={"Timewitch"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <TimewitchPostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <TimewitchPositionOverview />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                備考
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Note
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <TimewitchNote />
                        </Box>
                    </Box>

                </ModalBody>

                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'mailto:kurumi-komami@timewitch.jp'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };



    // Suntech Company PR
    const SuntechCompany = () => {
        sendDiscord('Suntechが閲覧されました．');

        const SuntechCompanyPR = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'ものづくり企業のリーディングカンパニーとして、オンリーワンの製品を作り続けています。ステンレス製のタンクや熱交換器、圧力容器の設計や製造を一貫して手掛けています。メイドイン・サンテックの製品は農業、食品、化学と多岐にわたる分野でその類に希なる精度が大きく評価されています。国内のみならずミャンマーやモロッコ、中国をはじめ世界各国でマーケットを拡大し、近年はインドネシア、フィリピン、台湾、タイ、ベトナム等を見据えています。\n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'As a leading company in the manufacturing industry, we continue to create one-of-a-kind products. We handle everything from the design to the manufacturing of stainless steel tanks, heat exchangers, and pressure vessels. Products made by us, under the "Made in Santeck" brand, are highly valued for their unparalleled precision across various sectors, including agriculture, food, and chemicals. Not limited to domestic markets, we are expanding our footprint globally, including countries such as Myanmar, Morocco, and China. In recent years, we have set our sights on markets in Indonesia, the Philippines, Taiwan, Thailand, and Vietnam.\n\n'}
                    </Text>
                </div>
            )
        };

        const SuntechBusinessContent = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'ステンレス製のタンクや熱交換器、真空機器、圧力容器等、インフラに欠かせないさまざまな産業機器を一貫体制で設計・製造しています。一級製管士や特別ボイラー溶接士など高い技術力を持つ職人が数多く揃い、手がけた製品は国内外から高く評価され、近年は海外でも事業を展開しています。一般には実物をあまり目にする機会がありませんが、「縁の下の力持ち」が注いだ息吹の結晶は医薬品や食品、スマートフォン、自動車、印刷、上下水道などあらゆる産業の工場で活躍。上下水道などあらゆる産業の工場で活躍しています。私たちの暮らしを身近なところで支えているのです。\n\n'}
                        {'次世代のSDGｓとして未来の地球環境を変える製品にも携わっています。増え続ける生ゴミの焼却と増え続けるCO2削減のために、注目されているのが、グループ会社である株式会社リソシアが設計開発した油温減圧式乾燥機 D-Cocotteです。 食品残渣が持つ水分を乾燥、滅菌させエコフィード(飼料)に変え循環型畜産業に貢献するシステムで、台湾、タイ、ハノイ、ホーチミン、ミャンマー、バングラデシュなどに加え、アフリカへの進出も視野に入れています。\n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'We operate with an integrated system for the design and manufacturing of various industrial equipment essential for infrastructure, including stainless steel tanks, heat exchangers, vacuum equipment,pressure vessels, etc. Our team comprises skilled craftsmen with high technical abilities, including certified pipe fitters and specialized boiler welders. The products we produce have been highly acclaimed both domestically and internationally, and we have been expanding our business overseas in recent years. While these products may not commonly be seen by the general public,  the "unsung heroes" serve as vital components actively utilized in factories across various sectors. These sectors include pharmaceuticals, food, smartphones, automobiles, printing, and water and sewage treatment. We play a vital role in supporting everyday life from behind the scenes.\n\n'}
                        {'We are also involved in products that will impact the future of the Earth\'s environment, aligning with next-generation Sustainable Development Goals (SDGs). Of particular interest is the oil-temperature reduction drying machine D-Cocotte, designed and developed by our group company, Resouxia. This system contributes to the circular livestock industry by drying and sterilizing the moisture in food waste and converting it into eco-feed. We are expanding our reach to Taiwan, Thailand, Hanoi, Ho Chi Minh City, Myanmar, Bangladesh, and are also considering entry into the African market.\n\n\n'}
                    </Text>
                </div>
            )
        };

        const SuntechPostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'・国際事業部 (海外渉外担当) \n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'・International Business Division (In charge of Overseas External Affairs) \n\n'}
                    </Text>
                </div>
            )
        };

        const SuntechPositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'勤務地:\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'香川県（国内・海外出張あり）\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'業務内容：\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'・セールス・マーケティング\n'}
                        {'・プロジェクトマネージメント\n'}
                        {'・クオリティマネジメント\n'}
                        {'・その他 (翻訳、通訳、注文書の作成、見積書の作成など)\n'}
                        {'※ターゲット顧客の国は、アジア圏（タイ・マレーシア・インドネシア・ベトナム・台湾・韓国など）\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'求める言語レベル：\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'日本語(ビジネスレベル)＋他２か国語 日常会話レベル以上（英語とアジア圏の言語希望）\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'その他要件（歓迎要件）：\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'貿易関連知識・マーケティング知識・経験歓迎\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'雇用形態：\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'正社員\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'給与・報酬：\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'20万～40万/月\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'就業時間や休日等：\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'8：00～17：00、休日はカレンダー通り（年間休日110日）、日祝その他会社が定めた日\n\n\n\n'}
                    </Text>


                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'Work Location:\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Kagawa Prefecture (Domestic and overseas business trips included)\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'Job Duties:\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'・Sales & Marketing\n'}
                        {'・Project Management\n'}
                        {'・Quality Management\n'}
                        {'・Others (Translation, interpretation, creation of purchase orders, creation of estimates, etc.)\n'}
                        {'※The target customers are located in the Asian region (countries such as Thailand, Malaysia, Indonesia, Vietnam, Taiwan, South Korea, etc.)\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'Required Language Skills:\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Japanese (Business Level) + 2 other languages (Daily conversation level or above / English and an Asian language preferred)\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'Additional Requirements (Desirable):\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Knowledge and experience in trade-related activities and marketing\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'Employment Type:\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Full-time\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'Salary:\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'200,000 to 400,000 JPY per month\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight={'bold'}>
                        {'Working Hours and Holidays:\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'8:00 am – 5:00 pm, holidays as per the calendar (110 annual holidays), Sundays, national holidays, and other days designated by the company.\n\n\n'}
                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'90%'} width={'35%'} objectFit={'contain'}
                            src={suntech} alt={"SUNTECH"} pt={10} pb={15} />
                        <Box textAlign="center">
                            <Heading size="lg">
                                SUNTECH
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='xl' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        Industrial Equipment Manufacturer
                    </Text>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                企業 PR
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company PR
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <SuntechCompanyPR />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'80%'} src={suntechImg1} alt={"Suntech"} m="auto" />
                        <Text whiteSpace="pre-line" fontWeight="bold" pt={10}></Text>
                        <Image width={'80%'} src={suntechImg2} alt={"Suntech"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                事業内容
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Business Content Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <SuntechBusinessContent />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={5} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'65%'} src={suntechImg3} alt={"Suntech"} m="auto" />
                    </Stack>


                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <SuntechPostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <SuntechPositionOverview />
                        </Box>
                    </Box>
                </ModalBody>

                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'https://suntech.link/recruit/entry/'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };


    // DXYZ Company PR
    const DXYZCompany = () => {
        sendDiscord('DXYZが閲覧されました．');
        const DXYZCompanyPR = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'創業３期目、社員数１８名のスタートアップ企業です。\n'}
                        {'『デジタルで、目に映る世界をより豊かに。』を会社のMissionに掲げ、『人と人が深く温もりをもってつながる』未来を実現するために、誰もが自由に顔認証サービスを開発・導入できるようにする顔認証プラットフォームFreeiDを展開しております。\n'}
                        {'現在、DXYZでは、顔認証に関する複数の特許を取得しており、開発したサービスは三菱地所や東京タワーのeスポーツパークなどに導入していただいております。\n'}
                        {'所属するメンバーは、三井物産、丸紅、デロイトFAS、電通、博報堂、キーエンス、山善、三井不動産リアルティを経験した人材が揃っています。\n\n'}
                        {'顔認証技術や当社の事業には日本を変える力があると信じています。\n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'We are a startup company established in 2020, with 18 employees. Our mission is "to enrich the real world through digital technology". We are developing and deploying the facial recognition platform FreeiD which enables consumers to use the facial recognition services in various scenes such as access control, identity verification, payment, and so on. Through this platform, we are aiming for a future where "people connect deeply and warmly with each other". Currently at DXYZ, we have obtained multiple patents related to facial recognition, and our platform has been adopted by companies like Mitsubishi Estate and Tokyo Tower\'s e-Sports Park. Our team consists of professionals with great experience at Japanese leading companies such as Mitsui & Co., Marubeni, Deloitte FAS, Dentsu, Hakuhodo, Keyence, Yamazen, and Mitsui Fudosan Realty.\n\n'}
                        {'We believe that our technology and business have the power to change Japan.\n\n'}
                    </Text>
                </div>
            )
        };

        const DXYZBusinessContent = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'顔認証プラットフォームFreeiDは、『顔ダケで、世界がつながる。』を目指しています。この共通顔認証IDプラットフォームでは、多種多様な顔認証AIエンジンと連携することが可能であるため、利用シーンに応じた最適なエンジンを選択することが可能となっています。そして、異なる顔認証AIエンジンでも１度の顔登録で利用可能であり、開発された様々な顔認証サービスにおいて何の障壁もなく利用することができます。\n'}
                        {'現在、マンション、オフィス、テーマパークなど、様々なサービスを開発提供しており、特にマンションサービスでは、「顔ダケで、暮らす」を実現するため、エントランス、エレベーターなどの共有部から居室のある各専有部までを顔認証で繋ぎ、鍵が一切いらない国内初のオール顔認証マンションとして展開しております。\n'}
                        {'今後は、「顔ダケで、働く」、「顔ダケで、遊ぶ」など色々な分野でサービスを開発強化していきます。\n\n\n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'Our facial recognition platform FreeiD aims for the world where people can do everything with only one face ID. The platform is versatile and capable of collaborating with a variety of facial recognition AI engines and therefore it allows us to select the most suitable one and develop the services depending on the applications. Also, whatever facial recognition AI engines the developed services might use, people can use all services seamlessly with a single facial registration. Currently, we provide services in various sectors like apartments, offices, and theme parks. For example, in the apartment sector, we are pioneering “all-facial recognition apartments”in Japan, which realize the residents’daily lives without any physical keys by implementing facial recognition devices from communal spaces like entrances and elevators to private residences.\n\n'}
                    </Text>
                </div>
            )
        };

        const DXYZPostionsAvailable = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                        {'■ エンジニア / Engineers \n\n'}
                        {'■ 事業開発 / Business Development\n\n'}
                        {'■ 営業 / Sales\n'}
                    </Text>
                </div>
            )
        };

        const DXYZPositionOverview = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line" fontWeight="bold">
                        {'・エンジニア: \n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'顔認証プラットフォームFreeiDのサービス開発を行います。少数精鋭での開発を行っており、サービス仕様からリリースまで多岐に渡る業務に携わることができます。また、自社サービスである顔認証システムの開発に携わることができ、幅広い技術に関わっていただくことも可能です。開発業務だけでなく、事業開発メンバーと一緒にサービス企画の段階から携わることも可能です。\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'・事業開発: \n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'顔認証プラットフォームFreeiDを新領域に拡大していく事業を開発していただきます。顔認証を利用した、新しい利用用途の市場調査から具体的なサービスの企画及び事業化までを担って頂きます。\n'}
                        {'モビリティ、決済など様々な場所で顔認証が使える『顔ダケで、世界がつながる』を一緒に実現しましょう。\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'・営業: \n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'顔認証プラットフォームFreeiDで開発されたソリューションを広める営業をしていただきます。不動産デベロッパーなど新しい顧客の獲得、各種ソリューションを販売する代理店の新規開拓を担って頂きます。\n'}
                        {'鍵が一切いらないオール顔認証マンションをはじめとする各種ソリューションを広める事で、「顔ダケで、暮らす」・「顔ダケで、働く」・「顔ダケで、遊ぶ」を世の中に浸透させた世界を一緒に実現しましょう。\n\n\n'}
                    </Text>

                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'・Engineers: \n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'You will work on the service development of the facial recognition platform FreeiD. You will be involved in a wide range of tasks from service design to production release in a small but highly skilled team. You will also have the opportunities to work on the development of our proprietary facial recognition systems and engage with a broad spectrum of technologies. You can also participate in the business planning stages along with the business development team.\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'・Business Development: \n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'You will be responsible for expanding the facial recognition platform FreeiD into new business domains. From market research for new applications of facial recognition to service planning and commercialization, you will be able to handle all of them. Let\'s work together to realize the world where people can do everything with only one face ID through creating various applications like mobility and payment.\n'}
                    </Text>
                    <Text whiteSpace="pre-line" fontWeight="bold" pt={10}>
                        {'・Sales: \n'}
                    </Text>
                    <Text whiteSpace="pre-line">
                        {'You will be responsible for acquiring new clients like real estate developers and creating new business partnerships. Let\'s work together to make the world where people can "live with just one face ID," "work with just one face ID," and "play with just one face ID."\n\n'}
                    </Text>
                </div>
            )
        };

        const DxyzNote = () => {
            return (
                <div>
                    <Text whiteSpace="pre-line">
                            {'イベント当日は、以下の時間で会社説明会及びQ&Aセッションを実施いたします。\n\n'}
                            {'On the day of the event, we will conduct company briefings and Q&A sessions according to the following schedule.\n\n\n'}
                            {'・1日目 / 10/28 (Sat)\n\n'}
                            {'　会社説明会/Company Briefing:\n　04:30PM～05:00PM・07:30PM～08:00PM (日本時間/JST)\n'}
                            {'　08:30AM～09:00AM・11:30AM～00:00PM  (イギリス夏時間/BST)\n'}
                            {'　Q&A Session:\n　05:00PM～06:30PM・08:00PM～09:30PM (日本時間/JST)\n'}
                            {'　09:00AM～10:30AM・00:00PM～01:30PM  (イギリス夏時間/BST)\n\n'}
    
                            {'・2日目 / 10/28 (Sat)\n\n'}
                            {'　Free Talk:\n　07:00PM～10:00PM (米国東部時間/EST)\n'}
                            {'　04:00PM～07:00PM  (米国西部時間/PST)\n'}
                            {'　会社説明会/Company Briefing:\n　10:30PM～11:00PM (米国東部時間/EST)\n'}
                            {'　07:30PM～08:00PM  (米国西部時間/PST)\n'}
                            {'　Q&A Session:\n　11:00PM～00:30AM (米国東部時間/EST)\n'}
                            {'　08:00PM～09:30PM  (米国西部時間/PST)\n\n'}
    
                    </Text>
                </div>
            )
        };

        return (
            <div>
                <ModalHeader>
                    <Container centerContent>
                        <Image height={'90%'} width={'35%'} objectFit={'contain'}
                            src={dxyz} alt={"DXYZ"} pt={10} pb={15} />
                        <Box textAlign="center">
                            <Heading size="lg">
                                DXYZ
                            </Heading>
                        </Box>
                    </Container>
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Text fontSize='xl' textAlign={'center'} mt={-2} whiteSpace="pre-line">
                        Technology/DX
                    </Text>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                企業 PR
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Company PR
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <DXYZCompanyPR />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={10} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'65%'} src={dxyzImg1} alt={"DXYZ"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                事業内容
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Business Content Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <DXYZBusinessContent />
                        </Box>
                    </Box>

                    <Stack spacing={'10%'} maxW={{ base: "100%", lg: "100%" }} px={20} mt={6} display="flex" alignItems={'center'} flexDirection="column" justifyContent={'center'}>
                        <Image width={'60%'} src={dxyzImg2} alt={"DXYZ"} m="auto" />
                        <Text whiteSpace="pre-line" fontWeight="bold" pt={10}></Text>
                        <Image width={'60%'} src={dxyzImg3} alt={"DXYZ"} m="auto" />
                    </Stack>

                    <Box px={10} mt={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                募集職種
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Postions Available
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <DXYZPostionsAvailable />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                職種概要
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Position Overview
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <DXYZPositionOverview />
                        </Box>
                    </Box>

                    <Box px={10}>
                        <Container centerContent mt={5} mb={2}>
                            <Heading fontSize={'xl'}>
                                備考
                            </Heading>
                            <Text fontSize={'xl'} textAlign={'center'}>
                                Note
                            </Text>
                        </Container>
                        <Divider border={'gray 1px solid;'} />
                        <Box mt={3}>
                            <DxyzNote />
                        </Box>
                    </Box>
                    
                </ModalBody>

                <ModalFooter justifyContent='center'>
                    <Button as={Link} href={'https://recruit.dxyz.co.jp/'} target="_blank" rel="noopener noreferrer"
                        background="green.800" textColor={"white"} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
                        Contact
                    </Button>
                </ModalFooter>
            </div>
        );
    };


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)' />
                <ModalContent maxW={{ base: "90vw", lg: "60vw" }} maxH={{ base: "70vh", lg: "80vh" }} overflow={'auto'} >
                    {title === 'mufg' && <MUFGCompany />}
                    {title === 'pinpoint' && <PinPOINTCompany />}
                    {title === 'plandosee' && <PlanDoSeeCompany />}
                    {title === 'fujifilm' && <FujifilmCompany />}
                    {title === 'cyberagent2' && <CyberAgentCompany />}
                    {title === 'timewitch' && <TimewitchCompany />}
                    {title === 'suntech' && <SuntechCompany />}
                    {title === 'dxyz' && <DXYZCompany />}
                </ModalContent>
            </Modal>
        </>
    )





}




export default CompanyIntro;