import { useEffect } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';

import HomePage from './HomePage';
import JapanCareerSummit from './JapanCareerSummit/JapanCareerSummit';
import JapanDay from './JapanCareerSummit/JapanDay';
import CompanyList from './JapanCareerSummit/CompanyList';
import CompanyCorporation from './JapanCareerSummit/CompanyCorporation';
import ComingSoon from './JapanCareerSummit/ComingSoon';

import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';

import { Notfound } from './error';


export const App = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // if there's a hash in the URL (i.e., "#my-id"), scroll to it
      let id = location.hash.replace('#', '');
      let element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth"
        });
      }
    } else {
      // otherwise, scroll to the top of the page
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/jcs' element={<JapanCareerSummit />} />
      <Route path='/jcs/co-list' element={<CompanyList />} />
      <Route path='/company-corporation' element={<CompanyCorporation />} />
      <Route path='/coming-soon' element={<ComingSoon />} />
      <Route path='/jd' element={<JapanDay />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='*' element={<Notfound />} />
    </Routes>
  )
}
