import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import {
  Flex, Box, Button, ButtonGroup, Spacer, Container, HStack, Stack, Text,
  Center, Heading, Table, Tbody, Tr, Td, TableContainer, SimpleGrid, Image, Link, VStack, Th, Divider
} from "@chakra-ui/react";

// components
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

// css
import styles from "../css/JapanCareerSummit.module.css";

// logos
import eventpic1 from '../logo/eventpic1.png'
import eventpic2 from '../logo/eventpic2.png'
import handshake from '../logo/handshake.png'
import connection from '../logo/connection.png'
import laptop from '../logo/laptop.png'
import run from '../logo/run.png'
import feather from '../logo/feather.png'
import companyinformation from '../logo/companyinformation.png'



function JapanCareerSummit() {
  const navigate = useNavigate();
  const handleBoxClick = () => {
    navigate(`/jcs/co-list`);
  }

  return (
    <div>
      <Navbar />
      <Container>
        <Center>
          <Heading size='lg' mt={10} mb={10}>Japan Career Summit</Heading>
        </Center>
        <Box m={["5", "4", "0", "0"]}>
          <Text fontSize={'lg'} align={'left'}>
            Welcome to the Japan Career Summit, a premier recruitment event
            designed to bridge world-class students with leading Japanese
            companies.<br />
            <br />
            Set in the virtual sphere of Gather (a 2D Metaverse tool), this unique event
            aims not only to provide exceptional job opportunities but also to foster
            powerful networks between students from top universities worldwide
            and corporate Japan. <br />
            <br />
            Join us on October 28th and 29th, 2023 (JST) in
            the Metaverse, to kickstart your global career journey.<br /><br />
            <Text fontWeight={'bold'}>*1: Exchange students from universities in Japan are not eligible. </Text><br />
            *2: No Japanese proficiency required.<br />
          </Text>
        </Box>
      </Container>
      <Flex direction={["column", "row"]} justify="center" align="center" wrap="wrap" style={{ marginTop: '80px' }}>
        <Box textAlign={'center'} mr={[0, 0]} mb={[10, 0]} className={styles.Metaverse} onClick={() => handleBoxClick()} >
          <Center>
            <Image mb={8} height={200} src={eventpic1} alt="Gather" />
          </Center>
          <Text mb={5} style={{ fontSize: "26px" }}>Metaverse</Text>
          <Text style={{ fontSize: "20px" }}>2023/10/28 (Sat) 16:00-22:00 (JST) <br />
            2023/10/29 (Sun) 08:00-14:00 (JST)</Text>
        </Box>
      </Flex>

      <Center>
        <ReactRouterLink to="/jcs/co-list">
          <Button size={'lg'} mt={3} bg={'green.600'} color={'white'} colorScheme={'green'} _hover={{ bg: 'green.900' }}>
            Detail
          </Button>
        </ReactRouterLink>
      </Center>

      <Center mt={"9%"} mb={"4%"} ml={"2%"} mr={"2%"}>
        <Text as={"i"} style={{ fontSize: "28px" }} align={"center"}>
          What can be gained by participating in the Summit?
        </Text>
      </Center>
      <Center mt={"2%"}>
        <HStack align="start" spacing={150} wrap={{ base: "wrap", md: "nowrap" }} >
          <Box textAlign={'center'} width={{ base: "100%", md: "100%" }} pr={{ base: "0", md: "0" }}>
            <Center>
              <Image mb={2} height={150} src={handshake} alt="job" />
            </Center>
            <Container maxW={'md'}>
              <Text mb={5} style={{ fontSize: "23px", fontWeight: "800" }}>Job offer</Text>
              <Text fontSize={{ base: "14px", md: "16px" }} maxW={{ base: "md", md: "md" }}>
                Participating students have the opportunity to secure internships and
                full-time job offers from Japanese companies and within Japan itself.
                Depending on the circumstances, some offers might be made on the spot.
                The attending companies are not only
                leading in Japan but also globally, boasting
                world-class services/products. <br />
              </Text>
            </Container>
          </Box>
          <Box textAlign={'center'} width={{ base: "100%", md: "100%" }} pr={{ base: "0", md: "0" }}>
            <Center>
              <Image mb={2} height={150} src={companyinformation} alt="company information" />
            </Center>
            <Container maxW={'md'}>
              <Text mb={5} style={{ fontSize: "23px", fontWeight: "800" }}>Company Information</Text>
              <Text fontSize={{ base: "14px", md: "16px" }} maxW={{ base: "md", md: "md" }}>
                On the day of the event, company executives, HR representatives, and employees will be present at the booth. 
                Attendees will have the opportunity to listen to company presentations, participate in Q&A sessions, and ask individual questions. 
                By interacting directly with current employees, you can gain a deeper understanding of the company.
              </Text>
            </Container>
          </Box>
        </HStack>
      </Center>
      <Center>
        <Box textAlign={'center'} width={{ base: "100%", md: "100%" }} pr={{ base: "0%", md: "0%" }} pt={{ base: "35%", md: "5%" }}>
              <Center>
                <Image mb={2} height={150} src={connection} alt="network" />
              </Center>
              <Container maxW={'md'}>
                <Text mb={5} style={{ fontSize: "23px", fontWeight: "800" }}>Prestigious Network</Text>
                <Text fontSize={{ base: "14px", md: "16px" }} maxW={{ base: "md", md: "md" }}>
                  Japan Career Summit brings together world-class students, executives, and human resources personnel from Japanese companies, providing an opportunity to forge lasting connections that will be mutually beneficial throughout your future career in Japan or with Japanese firms.
                </Text>
              </Container>
        </Box>
      </Center>
      <Center mt={"1.5%"}>
        <TableContainer maxW={'80vw'} display={'block'} overflowX={'auto'}>
          <Table textAlign='center' border="2px" borderColor="gray.500" className={styles.GuidelineTable}>
            <Th colSpan={2} textAlign='center' bg="green.800" color='white' fontWeight="800" fontSize={"large"}>Japan Career Summit Guideline</Th>        <Tbody>
              <Tr>
                <Td border="1px" borderColor="gray.500" textAlign='center' className={styles.tableNum}>1</Td>
                <Td border="1px" borderColor="gray.500" textAlign='center' pt={0}>
                  <h1 className={styles.tableTopic}><Image className={styles.tablePic} src={laptop} alt="Benefit" boxSize={{ base: "25%", md: "5%" }} /> Jelper Club Member Registration</h1>
                  <p className={styles.tableContent}>You must be a Jelper Club Member to register for the Career Summit.<br />
                    Please register from <a href="https://jelper.co/apply" className={styles.contactUs}>here</a>. <br />
                    That registration only takes 5 minutes.<br />
                    <span className={styles.smallNum}>1</span>  Registration with Jelper Club is subject to a specified screening process.<br />
                    <span className={styles.smallNum}>2</span> If you are already a member of Jelper Club, proceed to 2.
                  </p>
                </Td>
              </Tr>
              <Tr>
                <Td border="1px" borderColor="gray.500" textAlign='center' className={styles.tableNum}>2</Td>
                <Td border="1px" borderColor="gray.500" textAlign='center' pt={0}>
                  <h1 className={styles.tableTopic}> <Image className={styles.tablePic} src={feather} alt="Benefit" boxSize={{ base: "25%", md: "5%" }} /> Register for Japan Career Summit</h1>
                  <p className={styles.tableContent}>After registering Jelper Club, you can access to the registration form of the Career Summit.<br />
                    Please see the message box, open a message about the Career Summit from Jelper Club <br />
                    Inc. and click the link in the message. 
                    <br />If you can not find the link, click from <a href="https://forms.gle/o9WqWofKCKDAJTP67" className={styles.contactUs}>here</a>
                    <br />That registration process takes only 2-3 minutes.</p>
                </Td>
              </Tr>
              <Tr>
                <Td border="1px" borderColor="gray.500" textAlign='center' className={styles.tableNum}>3</Td>
                <Td border="1px" borderColor="gray.500" textAlign='center' pt={0}>
                  <h1 className={styles.tableTopic}><Image className={styles.tablePic} src={run} alt="Benefit"  boxSize={{ base: "25%", md: "5%" }} /> Participate in Japan Career Summit </h1>
                  <p className={styles.tableContent}>Jelper Club will send you the metaverse link of the event for members who register the<br />
                    Career Summit before the event.<br />
                    Please click the link and enter the virtual venue.<br />
                    *We will use Gather for the event. <br />
                    Regarding how to use it, please refer to <a href="https://support.gather.town/hc/en-us/categories/14871434949908-Getting-Started" className={styles.contactUs}>English guidance </a> / 
                    <a href="https://ja.gather.town/blog/guide-getting-started" className={styles.contactUs}>Japanese guidance </a>.
                  </p></Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
      <Footer />
    </div>
  )
}

export default JapanCareerSummit;