import {
    Box, Text, Center
} from "@chakra-ui/react";

import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

function CompanyCorporation() {
    return (
        <div>
            <Navbar />
            <Box minH={'calc(90vh - 100px)'}>

                <Center textAlign={"start"} m={"auto"} width="80vw" >
                    <Box width="100%" mt="50px">
                        <Text backgroundColor={"#F0F0F0"} fontWeight={"900"} p={"5px"} fontSize={"large"}>
                            &nbsp;&nbsp;ホームページリニューアル中のお知らせ
                        </Text>
                    </Box>
                </Center>

                <Center textAlign={"start"} m={"auto"} width="80vw" >
                    <Box width="100%">
                        <Text mt="30px" textAlign={"end"}>
                            2023-07-13
                        </Text>
                    </Box>
                </Center>

                <Center textAlign={"start"} m={"auto"} width="80vw" >
                    <Box mt="20px" width="100%">
                        <Text mt="30px" fontWeight={"500"}>
                            お客様 関係者様各位<br />
                            <br />
                            いつも当社のウェブサイトをご利用いただき、誠にありがとうございます。<br />
                            現在、当社のコーポレートサイトは、皆様により良いサービスと情報を提供するための改修作業を行っております。<br />
                            ご不便をおかけしており、深くお詫び申し上げます。<br />
                            改修作業が完了次第、より使いやすく、より充実したコンテンツをお届けしたく存じます。<br />
                            新しいウェブサイトの公開をお待ちいただいている皆様には、何卒ご理解とご協力をお願い申し上げます。<br />
                            <br />
                            Jelper Club Operation Team
                        </Text>
                    </Box>
                </Center>
            </Box>
            <Footer />
        </div>
    )
}
export default CompanyCorporation;