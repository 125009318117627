import { useNavigate } from "react-router-dom";
import { Link as RouterLink, } from 'react-router-dom';
import {
  Flex, Box, Button, ButtonGroup, Spacer, Container, HStack, Stack, Text,
  Center, Heading, Table, Tbody, Tr, Td, TableContainer, SimpleGrid, Image, Link, VStack, Th, Divider, useBreakpointValue
} from "@chakra-ui/react";



// components
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

// css
import styles from "../css/JapanCareerSummit.module.css";

// logos
import eventpic2 from '../logo/eventpic2.png'
import house from '../logo/house.png'
import laptop from '../logo/laptop.png'
import run from '../logo/run.png'
import feather from '../logo/feather.png'

import postera from '../poster/1.png';
import posterb from '../poster/2.png';
import posterc from '../poster/3.png';
import posterd from '../poster/4.png';
import postere from '../poster/5.png';
import posterf from '../poster/6.png';



import hands from '../logo/hands.png'
import plant from '../logo/plant.png'
import puzzle from '../logo/puzzle.png'
import bulb from '../logo/bulb.png'


function JapanDay() {
  const navigate = useNavigate();
  const handleBoxClick = (s: string) => {
    navigate(`/jcs/co-list?summit=${s}`);
  }

  return (
    <div>
      <Navbar />
      <Container>

        <Center>
          <Heading size='lg' mt={10} mb={2} textAlign='center'>Japan Day by Harvard Undergraduate Japan Initiative</Heading>
        </Center>


        <Flex direction={["column", "row"]} justify="center" align="center" wrap="wrap" style={{ marginTop: '20px' }}>
          <Box textAlign={'center'} mr={[0, 0]} mb={[0, 0]}>
            <Flex height="140px" alignItems="center" justifyContent="center">
              <Image mb={8} height={130} src={eventpic2} alt="Japan Day by Harvard Undergraduate Japan Initiative" borderRadius="50%" objectFit="cover" />
            </Flex>
            <Text style={{ fontSize: '2xl' }}>@Harvard Science Center</Text>
            <Text style={{ fontSize: '2xl' }}>(1 Oxford Street, Cambridge, MA, 02138)</Text>
            <Divider orientation="horizontal" my={2} />
            <Text style={{ fontSize: '2xl' }}>2023/11/15 (Wed) 4:00PM - 9:30PM (EST) <br />
              2023/11/16 (Thurs) 4:00PM - 9:00PM (EST)</Text>

          </Box>
        </Flex>

        <Center mt={5} mb={10}>
          <Button
            bgColor="#610C04"
            color="white"
            fontWeight="bold"
            mt={2}
            _hover={{
              bgColor: "#D21404"
            }}
            onClick={() => {
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
              });
            }}
          >
            Sign up
          </Button>

        </Center>


        <Box m={["5", "4", "0", "0"]}>
          <Text fontSize={'lg'} align={'left'}>
            The Harvard Undergraduate Japan Initiative (HUJI) is sponsoring a two-day Japan Day event to showcase Japanese culture, business, and more through panels. Key events include discussions on business in Japan, the startup ecosystem and venture capital, and Japan's cultural soft power.

            <br /><br />The event aims to increase understanding of Japan, provide engagement through fresh perspectives, and inspire active contribution and lifelong connections. It will culminate in an exclusive networking reception, "The Soirée Tokyo in Boston" sponsored by Jelper Club Inc. to extend conversations between students and speakers who share a passion for learning about Japan.
          </Text>
        </Box>
      </Container>

      <Box paddingLeft={["15%", "15%", "10%", "10%"]} paddingRight={["15%", "15%", "10%", "10%"]}>

        {/* First four benefits in a row */}
        <Center mt={"2%"}>
          <HStack spacing={5} align="stretch" wrap={{ base: "wrap", md: "nowrap" }} w="100%">

            {/* Awakening Interest */}
            <Box
              width={{ base: "100%", md: "24%" }}
              padding={{ base: "4", md: "8" }}
              boxShadow="md"
              bgGradient="linear(to-r, gray.50, gray.100)"
              borderRadius="md"
              minHeight="200px"
            >
              <Center>
                <Image mb={2} height={130} src={bulb} alt="bulb" />
              </Center>
              <Container maxW={'md'}>
                <Heading
                  as="h2"
                  mb={4}
                  size="md"
                  textAlign="center"
                  fontWeight="semibold"
                >
                  Awakening Interest
                </Heading>
              </Container>
            </Box>

            {/* Deep Engagement */}
            <Box
              width={{ base: "100%", md: "24%" }}
              padding={{ base: "4", md: "8" }}
              boxShadow="md"
              bgGradient="linear(to-r, gray.50, gray.100)"
              borderRadius="md"
              minHeight="200px"
            >
              <Center>
                <Image mb={2} height={130} src={hands} alt="hands" />
              </Center>
              <Container maxW={'md'}>
                <Heading
                  as="h2"
                  mb={4}
                  size="md"
                  textAlign="center"
                  fontWeight="semibold"
                >
                  Deep Engagement
                </Heading>
              </Container>
            </Box>

            {/* Active Contribution */}
            <Box
              width={{ base: "100%", md: "24%" }}
              padding={{ base: "4", md: "8" }}
              boxShadow="md"
              bgGradient="linear(to-r, gray.50, gray.100)"
              borderRadius="md"
              minHeight="200px"
            >
              <Center>
                <Image mb={7} height={110} src={puzzle} alt="puzzle" />
              </Center>
              <Container maxW={'md'}>
                <Heading
                  as="h2"
                  mb={4}
                  size="md"
                  textAlign="center"
                  fontWeight="semibold"
                >
                  Active Contribution
                </Heading>
              </Container>
            </Box>

            {/* Future Focused */}
            <Box
              width={{ base: "100%", md: "24%" }}
              padding={{ base: "4", md: "8" }}
              boxShadow="md"
              bgGradient="linear(to-r, gray.50, gray.100)"
              borderRadius="md"
              minHeight="200px"
            >
              <Center>
                <Image mb={7} height={110} src={plant} alt="plant" />
              </Center>
              <Container maxW={'md'}>
                <Heading
                  as="h2"
                  mb={4}
                  size="md"
                  textAlign="center"
                  fontWeight="semibold"
                >
                  Future Focused
                </Heading>
              </Container>
            </Box>

          </HStack>
        </Center>
      </Box>


      {/* Homestay Package in a separate section */}
      <Box paddingLeft={["15%", "15%", "10%", "10%"]} paddingRight={["15%", "15%", "10%", "10%"]}>
        <Center mt={"2%"}>
          <Box
            width={{ base: "100%", md: "100%" }}
            padding={{ base: "4", md: "8" }}
            boxShadow="md"
            bgGradient="linear(to-r, gray.50, gray.100)"
            borderRadius="md"
            minHeight="200px"
          >
            <Center>
              <Image mb={4} height={110} src={house} alt="package" />
            </Center>

            <Heading
              as="h2"
              mb={4}
              size="md"
              textAlign="center"
              fontWeight="semibold"
            >
              Homestay Package
            </Heading>

            <Text
              mb={4}
              fontSize={{ md: "16px" }}
            >
              Students participating in the Japan Day have the opportunity to benefit from a free package allowing them to homestay at a Harvard student dormitory. This immersive experience fosters deeper connections with Harvard students.
            </Text>
          </Box>
        </Center>
      </Box>



      <SimpleGrid columns={1} spacing={10} mb={10} pt="10" pl="15%" pr="15%">
        {[postera, posterb, posterc, posterd, postere, posterf].map((poster, index) => (
          <Box key={index} as="a" href={poster} target="_blank" rel="noopener noreferrer">
            <Image
              src={poster}
              alt={`Poster ${index + 1}`}
              borderRadius="md"
              boxShadow="lg"
              _hover={{ transform: 'scale(1.05)' }}
              width="100%" // Ensures the image takes up the full width of its container
            />
          </Box>
        ))}
      </SimpleGrid>








      <Box mt={"5%"} mb={"5%"} paddingLeft={["15%", "15%", "10%", "10%"]} paddingRight={["15%", "15%", "10%", "10%"]}>

        <Heading as="h2" size="lg" textAlign="center" fontWeight="semibold" mb={"4"}>
          Schedule and Featured Speakers
        </Heading>

        {/* Table for Nov 15 */}
        <Box bg="gray.50" p={4} borderRadius="md" mb={4} boxShadow="md" overflowX={'auto'}>
          <Heading as="h3" size="md" fontWeight="semibold" mb={"2"}>Nov 15 (Wed), 2023</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>4:00~4:20PM Opening</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>4:20~5:20PM Pop Culture Panel</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>Cultivating Creativity: Unraveling the Global Phenomenon of Japanese Anime and Gaming</Heading>
          <Box borderRadius="md" overflow="hidden" boxShadow="sm">
            <Table textAlign='center'>
              <Tbody>
                {/* Session 1 on Nov 15 */}
                <Tr bg="white">
                  <Td>
                    <Text>⚫ <strong>Dr. Shuichi Kurabayashi (倉林 修一)</strong>, Technical Advisor / Director of <strong>Cygames Research</strong>, Cygames, Inc.</Text>
                    <Divider orientation="horizontal" my={2} />
                    <Text>⚫ <strong>Arthell Isom</strong>, Co-founder of <strong>D'ART Shtajio</strong>.</Text>
                    <Divider orientation="horizontal" my={2} />
                    <Text>⚫ <strong>Prof. Tomiko Yoda (依田 富子)</strong>, Takashima Professor of Japanese Humanities, Harvard University.</Text>
                    <Divider orientation="horizontal" my={2} />
                    <Text>⚫ <strong>Prof. Tomomichi Amano (天野 友道)</strong>, Assistant Professor of Business Administration, Harvard Business School.</Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <Divider orientation="horizontal" my={4} />

          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>5:30~6:30PM Special Panel</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>Inside the Anime Studio: A Conversation with Trigger and Hiroyuki Imaishi</Heading>
          <Box borderRadius="md" overflow="hidden" boxShadow="sm">
            <Table textAlign='center'>
              <Tbody>
                {/* Session 1 on Nov 15 */}
                <Tr bg="white">
                  <Td>
                    <Text>⚫ <strong>Hiroyuki Imaishi (今石 洋之)</strong>, Co-founder of <strong>Studio TRIGGER</strong>, Renowned Animation Director with Notable Works from Neon Genesis Evangelion to Cyberpunk: Edgerunners, and the Visionary Behind GURREN LAGANN and KILL la KILL. </Text>
                    <Divider orientation="horizontal" my={2} />
                    <Text>⚫ <strong>Hiromi Wakabayashi (若林 広海)</strong>, Creative Director and Music Producer at <strong>Studio TRIGGER</strong>, actively involved in projects like KILL la KILL, Space Patrol Luluco, PROMARE, and Cyberpunk: Edgerunners.</Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <Divider orientation="horizontal" my={4} />
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>6:40PM~7:30 Startup Panel</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>Futurescape: Japan's Blossoming Startup Ecosystem</Heading>
          <Box borderRadius="md" overflow="hidden" boxShadow="sm">
            <Table textAlign='center'>
              <Tbody>
                {/* Session 1 on Nov 15 */}
                <Tr bg="white">
                  <Td>


                    <Text>⚫ <strong>Dr. Takuya Kitagawa (北川 拓也)</strong>, President and director of <strong>QuEra computing</strong>; Former Managing Executive Officer of Rakuten and CDO, Global Director of <strong>Rakuten Institute of Technology</strong>.</Text>
                    <Divider orientation="horizontal" my={2} />
                    <Text>⚫ <strong>Kenji Niwa (丹羽 健二)</strong>, Co-founder and CEO at <strong>Firstcard</strong>.</Text>
                    <Divider orientation="horizontal" my={2} />
                    <Text>⚫ <strong>Hideo Natsume (夏目 英男)</strong>, Co-founder of <strong>Asu Capital Partners</strong>.</Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <Divider orientation="horizontal" my={4} />
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>7:45~9:30PM Networking Reception <strong>"The Soirée Tokyo in Boston"</strong></Heading>
          <Box borderRadius="md" overflow="hidden" boxShadow="sm">
            <Table textAlign='center'>
              <Tbody>
                {/* Session 1 on Nov 15 */}
                <Tr bg="white">
                  <Td>
                    <Text>Enjoy food and sushi with speakers, <strong>sponsored by Jelper Club.</strong></Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>

        <Divider orientation="horizontal" my={2} />

        {/* Table for Nov 16 */}
        <Box bg="gray.50" p={4} borderRadius="md" mb={4} boxShadow="md" overflowX={'auto'}>
          <Heading as="h3" size="md" fontWeight="semibold" mb={"2"}>Nov 16 (Thurs), 2023</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>4:00~4:30PM Opening</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>4:30~5:30PM Business Panel</Heading>
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>Open Innovation & AI: Bridging Japan's Traditional Business with Modern Technological Evolution</Heading>
          <Box borderRadius="md" overflow="hidden" boxShadow="sm">
            <Table textAlign='center'>
              <Tbody>
                {/* Session 1 on Nov 15 */}
                <Tr bg="white">
                  <Td>
                    <Text>⚫ <strong>Masato Endo (遠藤 雅人)</strong>, Group Manager, <strong>Toyota Motor Corporation</strong>;  Automotive Chair, <strong>The Linux Foundation OpenChain Project</strong>.</Text>
                    <Divider orientation="horizontal" my={2} />
                    <Text>⚫ <strong>Prof. Matthew Brummer</strong>, Assistant Professor, National Graduate Institute for Policy Studies (GRIPS). Policy Innovations Fellow, Program on US-Japan Relations, Harvard University.</Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <Divider orientation="horizontal" my={4} />
          <Heading as="h3" size="sm" fontWeight="semibold" mb={"2"}>5:45~9:00PM Networking Reception <strong>"The Soirée Tokyo in Boston"</strong></Heading>
          <Box borderRadius="md" overflow="hidden" boxShadow="sm">
            <Table textAlign='center'>
              <Tbody>
                {/* Session 1 on Nov 15 */}
                <Tr bg="white">
                  <Td>
                    <Text>Enjoy food and sushi with speakers, <strong>sponsored by Jelper Club.</strong></Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box>



      <Box paddingLeft={["15%", "15%", "10%", "10%"]} paddingRight={["15%", "15%", "10%", "10%"]}></Box>
      <Center>
        <TableContainer maxW={'80vw'} display={'block'} overflowX={'auto'}>
          <Table textAlign='center' border="2px" borderColor="gray.500" className={styles.GuidelineTable}>
            <Th colSpan={2} textAlign='center' bg="#610C04" color='white' fontWeight="800" fontSize={"large"}>Japan Day Registration Guideline</Th>        <Tbody>
              <Tr>
                <Td border="1px" borderColor="gray.500" textAlign='center' className={styles.tableNum}>1</Td>
                <Td border="1px" borderColor="gray.500" textAlign='center' pt={0}>
                  <h1 className={styles.tableTopic}> <Image className={styles.tablePic} src={laptop} alt="Benefit" boxSize={{ base: "25%", md: "5%" }} /> Register for Japan Day</h1>
                  <p className={styles.tableContent}>
                    Please register the Japan Day through <a
                      href="https://forms.gle/7LHAEbTzyY6YeT8H6"
                      className={styles.contactUs}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>this link</strong>
                    </a>.
                  </p>
                  <p className={styles.tableContent}>
                  Deadline Extended to November 16th, 2023 11:59AM EST due to excessive interest!
                  </p>




                </Td>
              </Tr>
              <Tr>
                <Td border="1px" borderColor="gray.500" textAlign='center' className={styles.tableNum}>2</Td>
                <Td border="1px" borderColor="gray.500" textAlign='center' pt={0}>
                  <h1 className={styles.tableTopic}>
                    <Image className={styles.tablePic} src={feather} alt="Benefit" boxSize={{ base: "25%", md: "5%" }} />
                    Register for Homestay with Harvard students (Optional for non-Harvard Participants)
                  </h1>
                  <p className={styles.tableContent}>
                    After registering for the Japan Day event, Please register the Homestay through <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSey-sgi7ymKfoTq5QYUGZQnmVL1bIWZXXTTOcBs-gmQ4D0AfA/viewform?usp=sf_link"
                      className={styles.contactUs}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>this link</strong>
                    </a>.
                  </p>
                  <p className={styles.tableContent}>
                    Registration deadline: Nov 2nd, 2023 11:59PM EST
                  </p>
                  <p className={styles.tableContent}>
                    Result and confirmation will be sent around Nov 3rd, 2023
                  </p>
                  <p style={{ color: 'darkred', fontWeight: 'bold', textDecoration: 'underline' }}>
                    Homestay spots are very limited. Registration doesn't guarantee a spot; only our confirmation does.
                  </p>
                  <p style={{ color: 'darkred', fontWeight: 'bold', textDecoration: 'underline' }}>
                    We suggest booking a refundable hotel at the same time.
                  </p>

                </Td>

              </Tr>
              <Tr>
                <Td border="1px" borderColor="gray.500" textAlign='center' className={styles.tableNum}>3</Td>
                <Td border="1px" borderColor="gray.500" textAlign='center' pt={0}>
                  <h1 className={styles.tableTopic}><Image className={styles.tablePic} src={run} alt="Benefit" boxSize={{ base: "25%", md: "5%" }} /> Participate in Japan Day</h1>
                  <p className={styles.tableContent}>Harvard Undergraduate Japan Initiative will send you detail of how to enter the event on the day.<br />
                  </p></Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
      <Text textAlign="center" mt={'10'} mb={'10'} fontSize={'lg'} fontWeight={'bold'} >If you have any questions, feel free to ask us:<br /> huji.connect@gmail.com</Text>
      <Footer />
    </div>
  )
}

export default JapanDay;