import { useEffect } from "react";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Flex, Box, Button, ButtonGroup, Spacer, Container, HStack, Stack, Text,
  Center, Heading, Table, Tbody, Tr, Td, TableContainer, SimpleGrid, Image, Link, position,
} from "@chakra-ui/react";

import { Link as Scroll } from 'react-scroll';
import { ButtonMenu } from "./menu";

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

// logos
import logo from './logo/jelper-club-logo.png';
import qa from './logo/qa.png';
import chat from './logo/chat.png';
import suit from './logo/suit.png';
import present from './logo/present.png';
import eventpic1 from './logo/eventpic1.png'
import eventpic2 from './logo/eventpic2.png'


function HomePage() {

  const customHeadingStyles = {
    fontFamily: "Libre Baskerville, sans-serif",
  };


  return (
    <div>
      <Box h={'auto'} pb={'100px'} background={'linear-gradient(90deg, rgba(34, 92, 68, 1), rgba(122, 148, 94, 1) 70%);'} alignContent={'center'}>
        <Container as={Flex} position={'relative'} top={10} right={0} left={0} height={'70px'} maxW={'6xl'} alignItems={'center'} justifyContent={'space-evenly'}
          width={'100%'} alignContent={'center'} rounded={'md'}>
          <HStack>
            <Box>
              <Button as={Scroll} to={"#"} color='white' variant='ghost' colorScheme='blackAlpha' aria-label='Home Button' fontSize={'2xl'} >
                <Image src={logo} height={90} width={100} alt="Jelper-club" />
              </Button>
            </Box>
          </HStack>
          <Spacer />
          <HStack spacing={6} display={{ base: "none", md: "flex" }}>
            <ButtonGroup spacing={4}>
              <Button as={Scroll} to={"about"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='about Button' fontSize={'2xl'} >
                The Club
              </Button>
              <Button as={Scroll} to={"service"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='service Button' fontSize={'2xl'} >
                Service
              </Button>
              <Button as={Scroll} to={"member-event"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='event Button' fontSize={'2xl'} >
                Event
              </Button>
              <Button as={Scroll} to={"company"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='company Button' fontSize={'2xl'} >
                Company
              </Button>
              <Button as={Scroll} to={"contact"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='contact Button' fontSize={'2xl'} >
                Contact
              </Button>
              <Button as="a" href="https://www.corporate.jelper.co/" color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='companySummit Button' fontSize={'2xl'} >
                企業様はこちら
              </Button>
            </ButtonGroup>
          </HStack>
          <HStack spacing={6} display={{ base: "flex", md: "none" }}>
            <ButtonMenu />
          </HStack>
        </Container>
        <Box mt={'20vh'} color='White' textAlign={'center'}>
          <Heading
            as='h1'
            fontSize={{ base: "4xl", md: '6xl' }}
            sx={customHeadingStyles}
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.02)' }}
          >
            Jelper Club
          </Heading>

          <Text mt={1} fontSize={{ base: "2xl", md: "2xl" }} padding={4} fontFamily="Georgia" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.02)' }}>
            Be a Jelper.
          </Text>

          <Button
            mt={10}
            p={3}
            fontSize='xl'
            color='white'
            variant='link'
            style={{ boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.15)' }}
          >
            <Link href="https://jelper.co" >Apply/Sign-In</Link>
          </Button>

        </Box>
      </Box>

      <Box background={'white'} color='black' textAlign={'center'} mb={5} >
        <Container pt={20} pb={20} id="about" right={0} left={0} maxW={'4xl'} >
          <Heading size='xl'>The Club</Heading>
          <Text mt={10} fontSize='2xl' textAlign={'center'} >Established in 2023, Jelper Club aims to promote the exchange
            and share knowledge and opportunities among selected world leaders and Japanese companies through “Japan” as a common asset.</Text>
        </Container>
        <Container maxW={'4xl'} id="service">
          <Heading mb={5}>Service</Heading>
          <Text fontSize='2xl' textAlign={'center'}>Joining the Jelper Club gives you access to</Text>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacingX='40px' spacingY='20px' my={'16'}>
            <Box>
              <Center>
                <Image boxSize={'80px'} src={qa} alt="Q&A" />
              </Center>
              <Heading size='lg' mb={'4'}>Q&A</Heading>
              <Text fontSize={'lg'} align={'left'}>You can discuss a wide range of topics, from Japan to other topics, with world leaders through Q&A and gain new and innovative insights.
                You can also view past Q&A sessions and gain insights that can only be obtained within the Jelper Club.
              </Text>
            </Box>
            <Box>
              <Center>
                <Image boxSize={'80px'} src={chat} alt="Chat" />
              </Center>
              <Heading size='lg' mb={'4'}>Chat</Heading>
              <Text fontSize={'lg'} align={'left'}>
                You can chat one-on-one or in groups with world leaders. <br /> Through the chat, you can build closer connections with world leaders.
              </Text>
            </Box>
            <Box>
              <Center>
                <Image boxSize={'80px'} src={suit} alt="Opportunities" />
              </Center>
              <Heading size='lg' mb={'4'}>Opportunities</Heading>
              <Text fontSize={'lg'} align={'left'}>You can find opportunities to work in Japan or with Japanese companies through the jobs section.
              </Text>
            </Box>
            <Box>
              <Center>
                <Image boxSize={'80px'} src={present} alt="Benefit" />
              </Center>
              <Heading size='lg' mb={4}>Benefit</Heading>
              <Text fontSize={'lg'} align={'left'}>You can receive exclusive benefits for Jelper Club members.
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
        <Box textAlign={'center'} mb={10} id="member-event">
          <Heading mb={12} style={{ fontWeight: 'bold' }}>Members' Event</Heading>



          <Flex direction={{ base: "column", md: "row" }} justify="center" align="start" style={{ marginTop: '30px' }}>

            {/* Container for Japan Career Summit */}
            <Box width={{ base: "100%", md: "400px" }} mr={{ base: "0", md: "6" }}>
              <Flex direction="column" align="center">
                <Flex height="140px" alignItems="center">
                  <Image mb={8} height={130} src={eventpic1} alt="Benefit" objectFit="cover" />
                </Flex>
                <Heading fontSize={'2xl'} mb={3}>Japan Career Summit</Heading>
                <Text fontSize={'3xm'} color='gray.900' mb={4}>Imagine. Connect. Achieve.</Text>
                <Text fontSize={'lg'} align={'left'} padding={4} mb={4}>
                  Dive into a transformative experience that redefines career-building.
                  Connect with top Japanese companies. Immerse in the digital frontier of the Gather Metaverse from October 28-29, 2023 (JST).
                </Text>
                <Button
                  as={RouterLink}
                  to="/jcs"
                  bgColor="#225C44"
                  color="white"
                  mb={20}
                  fontWeight="bold"
                  _hover={{
                    bgColor: "#1D5340"
                  }}
                >
                  Detail
                </Button>
              </Flex>
            </Box>

            {/* Container for Japan Day */}
            <Box width={{ base: "100%", md: "400px" }} ml={{ base: "0", md: "6" }} mt={{ base: 20, md: 0 }}>
              <Flex direction="column" align="center">
                <Flex height="140px" alignItems="center">
                  <Image mb={8} height={130} src={eventpic2} alt="Japan Day" borderRadius="50%" objectFit="cover" />
                </Flex>
                <Heading fontSize={'2xl'} mb={3}>Japan Day</Heading>
                <Text fontSize={'3xm'} color='gray.900' mb={4}>by Harvard Undergraduate Japan Initiative</Text>
                <Text fontSize={'lg'} align={'left'} padding={4} mb={4}>
                  Spark interest across Harvard and beyond in all dimensions of Japan. Immerse in enlightening conversations through panel discussions on business, startups, and pop culture from November 15-16, 2023 (EST).
                </Text>
                <Button
                  as={RouterLink}
                  to="/jd"
                  bgColor="#610C04"
                  color="white"
                  fontWeight="bold"

                  mb={6}
                  _hover={{
                    bgColor: "#D21404"
                  }}
                >
                  Detail
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>


        <Box textAlign={'center'} mb={10} id="company">
          <Heading>Company</Heading>
        </Box>
        <Center>
          <TableContainer mb={20}>
            <Table variant='simple' fontSize={'xl'}>
              <Tbody>
                <Tr>
                  <Td fontWeight={'bold'}>Name</Td>
                  <Td>株式会社Jelper Club (Jelper Club Inc.)</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>Year Founded</Td>
                  <Td>2023</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>Headquarter</Td>
                  <Td>東京都世田谷区 (Setagaya-ku, Tokyo, Japan)</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>CEO</Td>
                  <Td>光澤大智 (Daichi Mitsuzawa)</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>Capital (Including capital reserves)</Td>
                  <Td>100万円 (1 million Yen)</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Center>
        <Box textAlign={'center'} mb={10} id="contact">
          <Heading mb={6}>Contact</Heading>
          <Text fontSize={'2xl'}>Click <Link fontSize={'2xl'} fontWeight={'bold'} textDecoration={'underline'} _hover={{ 'text-decoration': 'underline', 'color': 'green.800' }} href='https://forms.gle/V7KWWE55kgnoeYZW6'>here</Link> to contact us</Text>
        </Box>
      </Box >
      {/* Footer */}
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        mt={"auto"}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
        mb={4}
      >
        <Text>© 2023 Jelper Club. All rights reserved</Text>
        <Box textAlign={{ base: 'center', md: 'right' }}>
          <Text style={{ display: 'inline' }}>Terms and Conditions </Text>
          <Link style={{ textDecoration: "None" }} href="https://www.corporate.jelper.co/en/terms-and-conditions" > <Button mr={4} background={"white"}> <FontAwesomeIcon style={{ position: "relative", top: "-2px" }} icon={faArrowUpRightFromSquare} />
          </Button> </Link>
          <Text style={{ display: 'inline' }}>Privacy Policy </Text>
          <Link style={{ textDecoration: "None" }} href="https://www.corporate.jelper.co/en/privacy-policy" > <Button mr={4} background={"white"}> <FontAwesomeIcon style={{ position: "relative", top: "-2px" }} icon={faArrowUpRightFromSquare} />
          </Button> </Link>
          <Text style={{ display: 'inline' }}>Cookie Policy</Text>
          <Link style={{ textDecoration: "None" }} href="https://www.corporate.jelper.co/en/cookie-policy" > <Button mr={4} background={"white"}> <FontAwesomeIcon style={{ position: "relative", top: "-2px" }} icon={faArrowUpRightFromSquare} />
          </Button> </Link>
        </Box>
      </Container>
    </div >
  );
}

export default HomePage;
