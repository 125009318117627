import { useEffect, useState } from "react";
import {
    Flex, Box, Button, Text,
    Center, Image, Link
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import CompanyIntro from "./CompanyIntro";
import { Divider } from '@chakra-ui/react'

// css
import styles from "../css/CompanyList.module.css";

// logos due to cloudflare
import eventpic1 from '../logo/eventpic1.png'
import eventpic2 from '../logo/eventpic2.png'
import pinpoint from '../logo/pinpoint.png'
import MUFG from "../logo/mufg.png"
import plandosee from "../logo/plandosee.png"
import fujifilm from "../logo/fujifilm.jpg"
import dxyz from "../logo/dxyz.jpg"
import cyberagent1 from "../logo/cyberagent1.png"
import cyberagent2 from "../logo/cyberagent2.png"
import timewitch from "../logo/Timewitch.png"
import suntech from "../logo/suntech.jpg"

// components
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";


export interface CompanyListProps {
    img: string,
    type: string,
    title: string,
    time: string[],
    companyImg: Array<[string, string]>
}

const pinIMG1 = pinpoint
const mufgIMG1 = MUFG
const plandoseeIMG1 = plandosee
const fujifilmIMG1 = fujifilm
const dxyzIMG1 = dxyz
const cyberagentIMG1 = cyberagent2
const timewitchIMG1 = timewitch
const suntechIMG1 = suntech

const mufgIMG = "/static/media/mufg.cd240024def0664e8e53.png";
const pinpointIMG = "/static/media/pinpoint.70c193fcd188e477918a.png";
const plandoseeIMG = "/static/media/plandosee.231fab1695e5058a270e.png";
const fujifilmIMG = "/static/media/fujifilm.5174110718995526c857.jpg";
const cyberagentIMG = "/static/media/cyberagent2.307cbd3902b74d7ca18f.png";
const suntechIMG = "/static/media/suntech.299257350d64c5612475.jpg";
const dxyzIMG = "/static/media/dxyz.0c45f4ff532aaba05c81.jpg";
const timewitchIMG = "/static/media/Timewitch.c8b34e83a29519cdd9e9.png";

const companies: CompanyListProps[] = [
    {
        "img": "/static/media/eventpic1.498cb841de1a0f46c8b1.png",
        "type": "metaverse",
        "title": "Japan Career Summit @Gather (Metaverse)",
        "time": ["2023/10/28 (Sat) 16:00-22:00 (JST)", "2023/10/29 (Sun) 08:00-14:00 (JST)"],
        "companyImg": [["mufg", mufgIMG], ["pinpoint", pinpointIMG], ["plandosee", plandoseeIMG], ["fujifilm", fujifilmIMG],["cyberagent2", cyberagentIMG],["suntech", suntechIMG],["dxyz", dxyzIMG],["timewitch", timewitchIMG]]
    }, 
]

function CompanyList() {
    const [searchParams] = useSearchParams();
    const [company, setCompany] = useState(companies[0]);
    const [companyIntroTitle, setCompanyIntroTitle] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleCompanyIntroPlugin = (companyIntroTitle: string) => {
        setIsOpen(true);
        setCompanyIntroTitle(companyIntroTitle);
    }

    return (
        <div>
            <Navbar />
            <CompanyIntro isOpen={isOpen} onClose={() => setIsOpen(false)} title={companyIntroTitle} />

            <Center style={{ marginTop: '80px' }}>
                <Box textAlign={'center'} className={styles.Metaverse}>
                    <Center>
                        <Image mb={10} height={200} src={company.img} alt={company.type} />
                    </Center>

                    <Text mb={5} style={{ fontSize: "25px" }}>{company.title}</Text>
                    {company.time.map(t =>
                        <Text style={{ fontSize: "20px" }}>{t}</Text>
                    )}
                </Box>
            </Center>
            <Center>
                <Link href="https://forms.gle/ggtRTfdtBMR8M4gm9">
                    <Button mt={3} background="green.800" textColor={"white"} colorScheme={'green'}
                        _hover={{ bg: 'green.900' }} className={styles.joinButton} size={'lg'}>
                        Join
                    </Button>
                </Link>
            </Center>
            <Divider orientation='horizontal' />
            <Center>
                <Box className={styles.companyListBox} mt={30} pt={30}>
                    <Text className={styles.coListTitle} style={{fontSize: "25px" }}>
                        Participating Company List
                    </Text>
                    <Center className={styles.companyListCenter}>
                        {
                            company.companyImg.map(companyImg =>
                                <Box className={styles.companyImgBox1} onClick={() => handleCompanyIntroPlugin(companyImg[0])}>
                                    <Image src={companyImg[1]} className={styles.image} />
                                </Box>
                            )
                        }
                    </Center>
                    {/* <Center>
                        <Text className={styles.comingSoon}>Coming Soon</Text>
                    </Center> */}
                </Box>
            </Center>
            <Footer />
        </div>
    )
}

export default CompanyList;