import {
  Flex, Box, Button, ButtonGroup, Spacer, Container, HStack, Stack, Text,
  Center, Heading, Table, Tbody, Tr, Td, TableContainer, SimpleGrid, Image, Link,
} from "@chakra-ui/react";
import { Link as Scroll } from 'react-scroll';
import { ButtonMenu } from "../menu";
// import logo from '../logo/jelper-club-logo.png';
import { Link as RouterLink } from 'react-router-dom';

const logo = "/static/media/jelper-club-logo.dee4c75fbbd66ee76f2f.png";

export const Navbar = () => {

  return (
    <Box h={'auto'} pb={'100px'} background={'linear-gradient(90deg, rgba(34, 92, 68, 1), rgba(122, 148, 94, 1) 70%);'} alignContent={'center'}>
      <Container as={Flex} position={'relative'} top={10} right={0} left={0} height={'30px'} maxW={'6xl'} alignItems={'center'} justifyContent={'space-evenly'}
        width={'100%'} alignContent={'center'} rounded={'md'}>
        <HStack>
          <Box>
            <Button as={RouterLink} to={"/"} color='white' variant='ghost' colorScheme='blackAlpha' aria-label='Home Button' fontSize={'2xl'} >
              <Image src={logo} height={65} width={75} mt=
                {3.5} ml={2} alt="Jelper-club" />
            </Button>
          </Box>
        </HStack>
        <Spacer />
        <HStack spacing={6} display={{ base: "none", md: "flex" }}>
          <ButtonGroup spacing={4}>
            <Button as={RouterLink} to={"/"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='about Button' fontSize={'2xl'} >
              The Club
            </Button>
            <Button as={RouterLink} to={"/#service"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='service Button' fontSize={'2xl'} >
              Service
            </Button>
            <Button as={RouterLink} to={"/#member-event"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='event Button' fontSize={'2xl'} >
              Event
            </Button>
            <Button as={RouterLink} to={"/#company"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='company Button' fontSize={'2xl'} >
              Company
            </Button>
            <Button as={RouterLink} to={"/#contact"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='contact Button' fontSize={'2xl'} >
              Contact
            </Button>
            <Button as="a" href="https://www.corporate.jelper.co/" color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='companySummit Button' fontSize={'2xl'} >
              企業様はこちら
            </Button>
          </ButtonGroup>
        </HStack>
        <HStack spacing={6} display={{ base: "flex", md: "none" }}>
          <ButtonMenu />
        </HStack>
      </Container>
    </Box>
  )
}
