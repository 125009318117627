import {
  Box, Button, Container, Stack, Text, Link
} from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

// css
import styles from "../css/CompanyList.module.css";

export const Footer = () => {

  return (
    <Container
      as={Stack}
      maxW={'6xl'}
      py={4}
      mt={"auto"}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ base: 'center', md: 'space-between' }}
      align={{ base: 'center', md: 'center' }}
      mb={4}
    >
      <Text className={styles.termsAndConditions}>© 2023 Jelper Club. All rights reserved</Text>
      <Box textAlign={{ base: 'center', md: 'right' }}>
        <Text className={styles.termsAndConditions} style={{ display: 'inline' }}>Terms and Conditions</Text>
        <Link style={{ textDecoration: "None" }} href="https://www.corporate.jelper.co/terms-and-conditions" target="_blank" rel="noopener noreferrer" > <Button mr={4} background={"white"}> <FontAwesomeIcon style={{ position: "relative", top: "-2px" }} icon={faArrowUpRightFromSquare} />
        </Button> </Link>
        <Text className={styles.termsAndConditions} style={{ display: 'inline' }}>Privacy Policy</Text>
        <Link style={{ textDecoration: "None" }} href="https://www.corporate.jelper.co/privacy-policy" target="_blank" rel="noopener noreferrer" > <Button mr={4} background={"white"}> <FontAwesomeIcon style={{ position: "relative", top: "-2px" }} icon={faArrowUpRightFromSquare} />
        </Button> </Link>
        <Text className={styles.termsAndConditions} style={{ display: 'inline' }}>Cookie Policy</Text>
        <Link style={{ textDecoration: "None" }} href="https://www.corporate.jelper.co/cookie-policy" target="_blank" rel="noopener noreferrer"><Button mr={4} background={"white"}> <FontAwesomeIcon style={{ position: "relative", top: "-2px" }} icon={faArrowUpRightFromSquare} />
        </Button> </Link>
      </Box>
    </Container>
  )
};